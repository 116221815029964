import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Billboard } from '@renderbus/common/components'
import { Media } from '@renderbus/common/theme'
import { graphql, StaticQuery } from 'gatsby'
import MonkeyTitleImg from '../../images/index/banner-monkey-title.png'
import RayvisionCaseImg from '../../images/index/rayvision-case.png'

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  text-align: center;
`
const RayvisionCaseImgWrapper = styled.img`
  position: absolute;
  top: 60px;
  left: 252px;
  ${Media.lessThan(Media.small)} {
    left: 45px;
    width: 74px;
    height: 60px;
  }
`
const MonkeyTitleImgWrapper = styled.img`
  position: absolute;
  left: 160px;
  top: 266px;
  ${Media.lessThan(Media.small)} {
    width: 250px;
    height: 134px;
    left: 0;
    right: 0;
    top: 398px;
    margin: auto;
  }
`

class MonkeyKingBanner extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(relativePath: { regex: "/index/banner-monkey.png/" }) {
              ...fluidImage
            }
            bannerMb: file(relativePath: { regex: "/index/banner-monkey-mobile.png/" }) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb }) => (
          <BannerContainer>
            <MonkeyTitleImgWrapper
              src={MonkeyTitleImg}
              alt='4月2日再现大圣雄风 看暗黑版孙悟空大战群妖！'
            />
            <RayvisionCaseImgWrapper src={RayvisionCaseImg} alt='瑞云渲染案例' />
            <Billboard
              fluid={[
                bannerMb.childImageSharp.fluid,
                {
                  ...banner.childImageSharp.fluid,
                  media: `(min-width: 780px)`,
                },
              ]}
              alt='西游记-再世妖王'
            />
          </BannerContainer>
        )}
      />
    )
  }
}
export default MonkeyKingBanner
