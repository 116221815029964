import styled, { css } from 'styled-components'
import { Media, mbSize, pcSize, bannerSize } from '@renderbus/common/theme'
import Img from 'gatsby-image'
import { Container } from '../atom'
import { Billboard } from '@renderbus/common/components'

export const Mark = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pcSize(188)};
  height: ${pcSize(44)};
  background: #2180fc;
  border-radius: ${pcSize(22)};
  font-size: ${pcSize(18)};
  color: #dee4fe;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(188)};
    height: ${mbSize(44)};
    font-size: ${mbSize(18)};
    border-radius: ${mbSize(22)};
  }
`

export const TitleImg = styled(Img)`
  width: ${pcSize(634)};
  height: ${pcSize(64)};
  margin: ${pcSize(31)} 0 ${pcSize(26)} 0;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(634)};
    height: ${mbSize(64)};
    margin: ${mbSize(44)} 0 ${mbSize(36)} 0;
  }
`

export const SubTitleImg = styled(Img)`
  width: ${pcSize(521)};
  height: ${pcSize(60)};
  margin: 0 0 ${pcSize(30)} 0;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(521)};
    height: ${mbSize(60)};
    margin: 0 0 ${mbSize(32)} 0;
  }
`

export const Duration = styled.div`
  font-size: ${pcSize(22)};
  color: #fff1cf;
  font-family: 'Source Han Sans CN';
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(22)};
  }
`

export const ButtonImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: ${pcSize(179)};
  height: ${pcSize(59)};
  ${Media.lessThan(Media.small)} {
    padding: ${mbSize(0)};
    width: ${mbSize(179)};
    height: ${mbSize(59)};
  }
`

export const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${pcSize(28)} 0 0 0;
  width: ${pcSize(179)};
  height: ${pcSize(59)};
  cursor: pointer;
  span {
    position: absolute;
    top: ${pcSize(15)};
    left: 50%;
    font-size: ${pcSize(20)};
    color: #67360e;
    line-height: 1;
    transform: translate(-50%, 0);
  }
  .hover {
    visibility: hidden;
  }
  &:hover {
    .normal {
      visibility: hidden;
    }
    .hover {
      visibility: visible;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(179)};
    height: ${mbSize(59)};
    margin: ${mbSize(79)} 0 0 0;
    span {
      font-size: ${mbSize(20)};
      top: ${mbSize(15)};
    }
  }
`

export const Content = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: ${pcSize(40)};
  ${Media.lessThan(Media.small)} {
    margin-top: calc(${mbSize(357)} - 60px);
  }
`

export const MidAutumnContainer = styled(Container)`
  ${p =>
    p.inPage &&
    css`
      height: ${pcSize(910)};
      width: 100%;
      ${Billboard} {
        z-index: 0;
      }
      ${Content} {
        z-index: 1;
        margin-top: ${pcSize(72)};
      }
      ${Media.lessThan(Media.small)} {
        height: ${mbSize(1050)};
        ${Content} {
          margin-top: calc(${mbSize(237)} - 60px);
        }
      }
    `}
  ${Media.greaterThan(Media.small)} {
    ${p =>
      !p.inPage &&
      css`
        ${Content} {
          margin-top: ${bannerSize(40)};
        }
        ${Mark} {
          width: ${bannerSize(188)};
          height: ${bannerSize(44)};
          border-radius: ${bannerSize(22)};
          font-size: ${bannerSize(18)};
        }
        ${TitleImg} {
          width: ${bannerSize(634)};
          height: ${bannerSize(64)};
          margin: ${bannerSize(31)} 0 ${bannerSize(26)} 0;
        }
        ${SubTitleImg} {
          width: ${bannerSize(521)};
          height: ${bannerSize(60)};
          margin: 0 0 ${bannerSize(30)} 0;
        }
        ${Duration} {
          font-size: ${bannerSize(22)};
        }
        ${ButtonImg} {
          width: ${bannerSize(179)};
          height: ${bannerSize(59)};
        }
        ${ButtonWrapper} {
          margin: ${bannerSize(28)} 0 0 0;
          width: ${bannerSize(179)};
          height: ${bannerSize(59)};
          span {
            top: ${bannerSize(15)};
            font-size: ${bannerSize(20)};
          }
        }
      `}
  }
`
