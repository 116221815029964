import React from 'react'

const SvgHome02 = props => (
  <svg
    id='home_02_svg__\u56FE\u5C42_1'
    x={0}
    y={0}
    viewBox='0 0 75 75'
    xmlSpace='preserve'
    {...props}
  >
    <style>{'.home_02_svg__st0{fill:#13cb75}'}</style>
    <path
      className='home_02_svg__st0'
      d='M21.14 49.25H7c-2.21 0-4-1.79-4-4V24.96c0-2.21 1.79-4 4-4h14.14c1.1 0 2 .9 2 2v24.29c0 1.1-.9 2-2 2zM7 24.96v20.29h12.14V24.96H7zM54.06 45.48h-6.92c-1.1 0-2-.9-2-2V26.72c0-1.1.9-2 2-2h6.92c2.21 0 4 1.79 4 4v12.76c0 2.21-1.8 4-4 4zm-4.92-4h4.92V28.72h-4.92v12.76z'
    />
    <path
      className='home_02_svg__st0'
      d='M47.14 63.64c-.33 0-.67-.08-.97-.25L20.17 49a1.986 1.986 0 01-1.03-1.75V22.96c0-.73.4-1.4 1.03-1.75l26-14.39a1.998 1.998 0 012.96 1.75v53.07c0 .71-.37 1.36-.98 1.72-.31.19-.66.28-1.01.28zm-24-17.57l22 12.18V11.96l-22 12.18v21.93z'
    />
    <path
      className='home_02_svg__st0'
      d='M14.69 66.51h-3.23c-2.76 0-5-2.24-5-5V47.25c0-1.1.9-2 2-2h9.23c1.1 0 2 .9 2 2v14.26c0 2.76-2.25 5-5 5zm-4.24-17.26v12.26c0 .55.45 1 1 1h3.23c.55 0 1-.45 1-1V49.25h-5.23zM64.25 26.12a2 2 0 01-1.06-3.7l4.33-2.71a2 2 0 012.76.64 2 2 0 01-.64 2.76l-4.33 2.71c-.33.2-.7.3-1.06.3zM68.58 50.8c-.36 0-.73-.1-1.06-.3l-4.33-2.71a2.008 2.008 0 01-.64-2.76c.59-.94 1.82-1.22 2.76-.64l4.33 2.71c.94.59 1.22 1.82.64 2.76-.38.6-1.03.94-1.7.94zM68.58 37.1h-4.33c-1.1 0-2-.9-2-2s.9-2 2-2h4.33a2 2 0 110 4z'
    />
  </svg>
)

export default SvgHome02
