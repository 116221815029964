import React from 'react'
import { Billboard } from '@renderbus/common/components'
import { useStaticQuery, graphql } from 'gatsby'
import { BannerContainer } from './atom'
import styled, { createGlobalStyle } from 'styled-components'
import { Media, mbSize } from '@renderbus/common/theme'
import SourceHanSansCNHeavy from '../../font/SourceHanSansCN-Heavy.otf'

const SummerBanner2024Style = createGlobalStyle`
  @font-face {
    font-family: 'SourceHanSansCN-Heavy';
    src: url(${SourceHanSansCNHeavy});
    font-style: normal;
    font-weight: normal;
    font-display: fallback;
  }
`

const ContentWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const FilmWrapper = styled.div`
  position: relative;
  z-index: 0;
  margin-top: 245px;
  width: 513px;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(620)};
    height: ${mbSize(200)};
    margin-top: ${mbSize(150)};
  }
`

const FilmTitle = styled.div`
  font-weight: bolder;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 8px;
  margin-top: 12px;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(30)};
    letter-spacing: ${mbSize(10)};
    margin-top: ${mbSize(15)};
  }
`

const FilmSubTitle = styled.div`
  font-family: SourceHanSansCN-Heavy;
  font-weight: 800;
  font-size: 60px;
  margin-top: 15px;
  background: linear-gradient(0deg, #aaaaaa 0%, #ffffff 39.990234375%);
  color: transparent;
  background-clip: text;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(72)};
    margin-top: ${mbSize(30)};
    background: transparent;
    color: #ffffff;
  }
`

const FilmDesc = styled.div`
  width: 779px;
  height: 46px;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  line-height: 30px;
  margin-top: 10px;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

const FilmDescMb = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(24)};
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: ${mbSize(48)};
    margin-top: ${mbSize(10)};
  }
`

export const SummerBanner2024 = () => {
  const { banner, bannerMb, film } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "index/summer-banner-2024/bg.webp" }) {
        ...fluidImage
      }
      bannerMb: file(relativePath: { eq: "index/summer-banner-2024/bg-mb.webp" }) {
        ...fluidImage
      }
      film: file(relativePath: { eq: "index/summer-banner-2024/film.webp" }) {
        ...fluidImage
      }
    }
  `)
  return (
    <BannerContainer style={{ display: 'flex', justifyContent: 'center' }}>
      <ContentWrap>
        <FilmWrapper>
          <FilmTitle>瑞云渲染助力2024年暑期档</FilmTitle>
          <FilmSubTitle>热映电影及番剧</FilmSubTitle>
          <Billboard fluid={film.childImageSharp.fluid} alt='胶卷' />
        </FilmWrapper>
        <FilmDesc>
          《二郎神之深海蛟龙》《落凡尘》《欢迎来到我身边》《斗破苍穹年番3》《眷思量之风烟迭起》《回铭之烽火三月》均由
          Renderbus 瑞云渲染提供云渲染服务
        </FilmDesc>
        <FilmDescMb>
          <span>《二郎神之深海蛟龙》《落凡尘》《欢迎来到我身边》</span>
          <span>《斗破苍穹年番3》《眷思量之风烟迭起》《回铭之烽火三月》</span>
          <span>均由Renderbus 瑞云渲染提供云渲染服务</span>
        </FilmDescMb>
      </ContentWrap>
      <Billboard
        fluid={[
          banner.childImageSharp.fluid,
          {
            ...bannerMb.childImageSharp.fluid,
            media: `(max-width: 780px)`,
          },
        ]}
        alt='瑞云Renderbus渲染农场-暑期档'
      />
      <SummerBanner2024Style />
    </BannerContainer>
  )
}
