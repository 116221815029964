export default class CmsContentService {
  static getCoverImg(content) {
    const contentfulCoverImgPattern = '!\\[.*\\]\\((.*?)\\)'
    const databaseCoverImgPattern = '<img[\\s\\S]*?src="(.*?)"'
    const imgCoverRegex = new RegExp(`(${contentfulCoverImgPattern}|${databaseCoverImgPattern})`)
    const matchResult = content.match(imgCoverRegex)
    if (matchResult) {
      return matchResult[2] || matchResult[3]
    }
    return ''
  }
}
