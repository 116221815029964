import React from 'react'
import styled from 'styled-components'
import { Billboard, H4 } from '@renderbus/common/components'
import { Media, typography } from '@renderbus/common/theme'
import { graphql, Link, useStaticQuery } from 'gatsby'
import BannerTextImg from '../../images/banner/picture/year-end-recharge-2022-text.png'
import BannerTextImgMb from '../../images/banner/picture/year-end-recharge-2022-text-mb.png'

export const PictureContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  color: white;
  ${Media.lessThan(Media.small)} {
    padding-top: 150px;
    align-items: flex-start;
    text-align: center;
  }
`

export const PictureBannerButton = styled(Link)`
  position: absolute;
  color: #0f140f;
  font-size: ${typography.text};
  width: 152px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: #53d769;
  border-radius: 19px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  ${Media.lessThan(Media.small)} {
    bottom: -12.27vw;
    width: 29.33vw;
    height: 8vw;
    line-height: 8vw;
    border-radius: 30px;
  }
`

export const PictureBannerTextImg = styled.div`
  background: ${`url(${BannerTextImg})`} no-repeat center center;
  height: 418px;
  width: 901px;
  margin-top: 132px;
  position: relative;
  ${Media.lessThan(Media.small)} {
    background: ${`url(${BannerTextImgMb})`} no-repeat;
    background-size: 100%;
    margin-top: 0px;
    max-width: 100vw;
    max-height: 46.26vw;
  }
`

export const PictureBannerDate = styled(H4)`
  color: #bec3c5;
  margin-top: 27px;
  font-weight: 400;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    text-align: center;
    margin-top: 16.27vw;
    font-size: 3vw;
  }
`

export default React.memo(function PictureBanner() {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { regex: "/banner/picture/year-end-recharge-2022-bg.png/" }) {
        ...fluidImage
      }
      bannerMb: file(relativePath: { regex: "/banner/picture/year-end-recharge-2022-bg-mb.png/" }) {
        ...fluidImage
      }
    }
  `)
  return (
    <PictureContainer>
      <PictureBannerTextImg>
        <PictureBannerButton to='/xgtnzhd.html' id='xgtnzhd-ljxq'>
          了解详情
        </PictureBannerButton>
      </PictureBannerTextImg>
      <PictureBannerDate>活动时间：2022年11月1日～12月31日</PictureBannerDate>
      <Billboard
        fluid={[
          data.banner.childImageSharp.fluid,
          { ...data.bannerMb.childImageSharp.fluid, media: `(max-width: 780px)` },
        ]}
      />
    </PictureContainer>
  )
})
