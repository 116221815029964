import React from 'react'

const SvgHome08 = props => (
  <svg id='home_08_svg___\u56FE\u5C42_1' data-name='\u56FE\u5C42 1' viewBox='0 0 75 75' {...props}>
    <defs>
      <style>{'.home_08_svg__cls-1{fill:#13cb75;stroke-width:0}'}</style>
    </defs>
    <path
      className='home_08_svg__cls-1'
      d='M64.7 18.5c-.8 0-1.6-.5-1.9-1.3l-1.3-3.7c-.5-1.3-1.9-2.2-3.3-2l-47.6 6.9c-1.1.2-2.1-.6-2.3-1.7s.6-2.1 1.7-2.3l47.6-6.9c3.3-.5 6.5 1.4 7.6 4.6l1.3 3.7c.4 1-.2 2.2-1.2 2.6-.2 0-.5.1-.7.1zM30.8 48h-3V36.6c-1.1 1-2.4 1.8-3.9 2.3v-2.7c.8-.3 1.6-.7 2.6-1.5.9-.7 1.6-1.6 1.9-2.5h2.4V48zM40.4 32.2c1.5 0 2.7.5 3.6 1.6 1 1.3 1.5 3.4 1.5 6.4s-.5 5.1-1.5 6.4c-.9 1.1-2 1.6-3.6 1.6s-2.8-.6-3.7-1.8c-.9-1.2-1.4-3.3-1.4-6.3s.5-5.1 1.5-6.4c.9-1.1 2-1.6 3.6-1.6zm0 2.5c-.4 0-.7.1-1 .3-.3.2-.5.7-.7 1.3-.2.8-.3 2.1-.3 3.9s0 3.1.3 3.8c.2.7.4 1.2.7 1.4.3.2.6.3 1 .3s.7-.1 1-.3c.3-.2.5-.6.7-1.3.2-.8.3-2.1.3-3.9s0-3.1-.3-3.8c-.2-.7-.4-1.2-.7-1.4-.3-.2-.6-.3-1-.3zM52.6 32.2c1.5 0 2.7.5 3.6 1.6 1 1.3 1.5 3.4 1.5 6.4s-.5 5.1-1.5 6.4c-.9 1.1-2 1.6-3.6 1.6s-2.8-.6-3.7-1.8c-.9-1.2-1.4-3.3-1.4-6.3S48 35 49 33.7c.9-1.1 2-1.6 3.6-1.6zm0 2.5c-.4 0-.7.1-1 .3-.3.2-.5.7-.7 1.3-.2.8-.3 2.1-.3 3.9s0 3.1.3 3.8c.2.7.4 1.2.7 1.4.3.2.6.3 1 .3s.7-.1 1-.3c.3-.2.5-.6.7-1.3.2-.8.3-2.1.3-3.9s0-3.1-.3-3.8c-.2-.7-.4-1.2-.7-1.4-.3-.2-.6-.3-1-.3zM18.4 38.1h-1.8V37h1.8v-.6h-1.8v-1.1h1.3l-1.5-3.1h1.5l1 2.1c.1.3.2.5.3.7 0-.2.1-.4.3-.7l1-2.1H22l-1.5 3.1h1.3v1.1H20v.6h1.8v1.1H20v1.3h-1.4v-1.3z'
    />
    <rect className='home_08_svg__cls-1' x={13.1} y={22.2} width={6} height={4} rx={1} ry={1} />
    <rect className='home_08_svg__cls-1' x={21.6} y={22.2} width={6} height={4} rx={1} ry={1} />
    <rect className='home_08_svg__cls-1' x={30.2} y={22.2} width={6} height={4} rx={1} ry={1} />
    <rect className='home_08_svg__cls-1' x={38.8} y={22.2} width={6} height={4} rx={1} ry={1} />
    <rect className='home_08_svg__cls-1' x={47.4} y={22.2} width={6} height={4} rx={1} ry={1} />
    <rect className='home_08_svg__cls-1' x={55.9} y={22.2} width={6} height={4} rx={1} ry={1} />
    <rect className='home_08_svg__cls-1' x={13.1} y={54.9} width={6} height={4} rx={1} ry={1} />
    <rect className='home_08_svg__cls-1' x={21.6} y={54.9} width={6} height={4} rx={1} ry={1} />
    <rect className='home_08_svg__cls-1' x={30.2} y={54.9} width={6} height={4} rx={1} ry={1} />
    <rect className='home_08_svg__cls-1' x={38.8} y={54.9} width={6} height={4} rx={1} ry={1} />
    <rect className='home_08_svg__cls-1' x={47.4} y={54.9} width={6} height={4} rx={1} ry={1} />
    <rect className='home_08_svg__cls-1' x={55.9} y={54.9} width={6} height={4} rx={1} ry={1} />
    <path
      className='home_08_svg__cls-1'
      d='M65 66.5H10c-3.9 0-7-3.1-7-7v-13c0-1.1.9-2 2-2 2.2 0 4-1.8 4-4s-1.8-4-4-4-2-.9-2-2v-13c0-3.9 3.1-7 7-7h55c3.9 0 7 3.1 7 7v13c0 1.1-.9 2-2 2-2.2 0-4 1.8-4 4s1.8 4 4 4 2 .9 2 2v13c0 3.9-3.1 7-7 7zM7 48.3v11.3c0 1.7 1.3 3 3 3h55c1.7 0 3-1.3 3-3V48.3c-3.4-.9-6-4-6-7.7s2.6-6.9 6-7.7V21.6c0-1.7-1.3-3-3-3H10c-1.7 0-3 1.3-3 3v11.3c3.4.9 6 4 6 7.7s-2.6 6.9-6 7.7z'
    />
  </svg>
)

export default SvgHome08
