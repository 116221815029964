import React from 'react'
import { Media, mbSize } from '@renderbus/common/theme'
import { graphql, useStaticQuery } from 'gatsby'
import styled, { css } from 'styled-components'
import { Billboard } from '@renderbus/common/components'
import TitleImg from '../../images/banner/architectural-rendering-monthly-promotion/text.png'
import TitleMbImg from '../../images/banner/architectural-rendering-monthly-promotion/text_mobile.png'
import Img from 'gatsby-image'
import { navigate } from 'gatsby'

export const PictureBannerContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: ${p => (p.isBanner ? '100vh' : '1080px')};
  color: white;
  font-family: 'Source Han Sans CN';
  .mb {
    display: none !important;
  }
  ${p =>
    !p.isBanner &&
    css`
      ${Billboard} {
        z-index: 0;
      }
    `}
  ${Media.lessThan(Media.small)} {
    height: ${p => (p.isBanner ? '100vh' : mbSize(2093))};
    .mb {
      display: flex !important;
    }
    .pc {
      display: none !important;
    }
  }
`

export const XgtMark = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 228px;
  height: 42px;
  background: #1357db;
  font-size: 20px;
  color: #ffffff;
  border-radius: 60px 60px 60px 60px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(256)};
    height: ${mbSize(48)};
    font-size: ${mbSize(24)};
    margin-top: ${mbSize(30)};
  }
`

export const Tip = styled.div`
  display: flex;
  align-items: center;
  width: 645px;
  height: 60px;
  background: linear-gradient(
    270deg,
    rgba(117, 172, 255, 0) 0%,
    rgba(27, 117, 255, 0.68) 28%,
    rgba(16, 111, 255, 0.61) 67%,
    rgba(5, 103, 255, 0) 100%
  );
  padding-left: 26px;
  margin-left: -26px;
  margin-top: 30px;
  font-size: 24px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(588)};
    height: ${mbSize(60)};
    font-size: ${mbSize(28)};
    padding-left: unset;
    margin-left: unset;
    margin-top: ${mbSize(27)};
  }
`

export const BannerWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 1200px;
  justify-content: ${p => (p.isBanner ? 'center' : 'flex-start')};
  margin-left: -74px;
  ${p =>
    !p.isBanner &&
    css`
      z-index: 1;
    `}
  margin-top: ${p => (p.isBanner ? '-60px' : '145px')};
  .text-wrapper {
    position: relative;
    width: 648px;
    height: 202px;
    margin-top: 30px;
    margin-left: -10px;
    img {
      width: 100%;
    }
    .date {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 49px;
      right: 0;
      .label {
        font-size: 18px;
      }
      .value {
        font-weight: 900;
        font-size: 22px;
      }
      &.in-page {
        display: none !important;
      }
    }
  }
  .date.in-page {
    display: flex !important;
    margin-top: 24px;
    font-size: 18px;
    color: #c0ddff;
  }
  ${Media.lessThan(Media.small)} {
    width: 100vw;
    margin-left: unset;
    align-items: center;
    justify-content: unset;
    padding-top: 60px;
    margin-top: unset;
    .text-wrapper {
      width: ${mbSize(583)};
      height: ${mbSize(182)};
      margin-top: ${mbSize(24)};
    }
    .date.in-page {
      position: relative;
      align-items: center;
      color: #daebff;
      font-size: ${mbSize(18)};
      margin-top: ${mbSize(18)};
    }
  }
`
export const SmallIcon = styled(Img)`
  width: 30px;
  height: 26px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(28)};
    height: ${mbSize(24)};
    font-size: ${mbSize(18)};
  }
`
export const Desc = styled.div`
  display: flex;
  font-size: 30px;
  color: #ffffff;
  line-height: 29px;
  /* text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.4); */
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
  letter-spacing: 1px;
  margin: 16px 0 40px;
  p {
    margin: 0;
  }
  span {
    display: flex;
    align-items: center;
    color: #ffecb2;
    font-weight: bold;
    ${SmallIcon} {
      margin: 0 8px;
    }
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(27)};
    line-height: unset;
    letter-spacing: ${mbSize(2)};
    margin: ${mbSize(16)} 0 ${mbSize(40)};
    span {
      ${SmallIcon} {
        margin: 0 ${mbSize(8)};
      }
    }
  }
`

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 190px;
  height: 52px;
  border-radius: 60px;
  font-size: 18px;
  color: #894a00;
  margin-top: 40px;
  background: linear-gradient(90deg, #fff9e3 0%, #ffd95c 100%);
  transition: all 0.3s;
  :hover {
    background: linear-gradient(90deg, #fff9e3 0%, #ffd95c 100%);
    box-shadow: 0px 4px 10px 0px rgba(87, 66, 0, 0.3);
  }
  cursor: pointer;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(190)};
    height: ${mbSize(52)};
    font-size: ${mbSize(18)};
    margin-top: ${mbSize(21)};
  }
`

export default React.memo(function ArchitecturalRenderingMonthlyPromotionBanner({
  isBanner = true,
}) {
  const data = useStaticQuery(graphql`
    query {
      banner: file(
        relativePath: { regex: "/banner/architectural-rendering-monthly-promotion/bg.png/" }
      ) {
        ...fluidImage
      }
      bannerPage: file(
        relativePath: { regex: "/banner/architectural-rendering-monthly-promotion/bg_page.png/" }
      ) {
        ...fluidImage
      }
      bannerMb: file(
        relativePath: {
          regex: "/banner/architectural-rendering-monthly-promotion/banner_bg_mobile.png/"
        }
      ) {
        ...fluidImage
      }
      bannerMbPage: file(
        relativePath: {
          regex: "/banner/architectural-rendering-monthly-promotion/banner_bg_mobile_page.png/"
        }
      ) {
        ...fluidImage
      }
    }
  `)

  const handleClick = e => {
    if (!isBanner) return
    e.nativeEvent.stopImmediatePropagation()
    navigate('/architectural-rendering-monthly-promotion.html')
  }

  return (
    <PictureBannerContainer isBanner={isBanner} onClick={handleClick}>
      <BannerWrap isBanner={isBanner}>
        <XgtMark>效果图用户专享</XgtMark>
        <div className='text-wrapper'>
          <img src={TitleImg} alt='' className='pc' />
          <img src={TitleMbImg} alt='' className='mb' />
          <div className={`date pc ${isBanner ? '' : 'in-page'}`}>
            <span className='label'>活动时间：</span>
            <span className='value'>2024年8月8日-9月8日</span>
          </div>
        </div>
        <Tip>活动期间充值，最高可领取12月效果图会员</Tip>
        <div className={`date mb ${isBanner ? '' : 'in-page'}`}>
          <span className='label'>活动时间：</span>
          <span className='value'>2024年8月8日-9月8日</span>
        </div>
        {isBanner && <Button onClick={handleClick}>{'了解详情'}</Button>}
      </BannerWrap>
      <Billboard
        fluid={[
          (isBanner ? data.banner : data.bannerPage).childImageSharp.fluid,
          {
            ...(isBanner ? data.bannerMb : data.bannerMbPage).childImageSharp.fluid,
            media: `(max-width: 780px)`,
          },
        ]}
      />
    </PictureBannerContainer>
  )
})
