import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import { Billboard, Button } from '@renderbus/common/components'
import { Media, hideSMUp, hideSMDown } from '@renderbus/common/theme'
import { graphql, Link, StaticQuery } from 'gatsby'
import ContentImage from '../../images/picture-price-down/content.png'
import ContentMobileImage from '../../images/picture-price-down/content-mobile.png'
import TinyImage from '../../images/picture-price-down/tiny.png'
import TinyMobileImage from '../../images/picture-price-down/tiny-mobile.png'
import couponImage from '../../images/picture-price-down/coupon.png'
import { mbSize } from '@renderbus/common/theme'

const ContentImg = styled.img`
  ${hideSMDown};
`
const ContentMobileImg = styled.img`
  ${hideSMUp};
  width: ${mbSize(604)};
`
const TinyImg = styled.img`
  top: 101px;
  ${hideSMDown};
`
const TinyMobileImg = styled.img`
  ${hideSMUp};
  width: ${mbSize(669)};
  top: 29.84vh;
`
const CouponImg = styled.img`
  ${hideSMUp};
  width: 100vw;
  top: 11.54vh;
`
const DetailButton = styled(Button)`
  position: absolute;
  bottom: 97px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 200px;
  height: 50px;
  line-height: 50px;
  background: linear-gradient(180deg, #fef4d1 0%, #f3d19e 100%);
  border-radius: 25px;
  font-weight: 400;
  font-size: 20px;
  &:hover {
    box-shadow: 0px 6px 11px 1px rgba(199, 137, 93, 0.3);
  }
  ${Media.lessThan(Media.small)} {
    bottom: ${mbSize(-74)};
    transform: translate(-50%, 100%);
    width: ${mbSize(240)};
    height: ${mbSize(70)};
    line-height: ${mbSize(70)};
    font-size: ${mbSize(30)};
  }
`
const ContentWrapper = styled.div`
  top: 133px;
  position: relative;
  height: 584px;
  img {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
  ${Media.lessThan(Media.small)} {
    top: 23.46vh;
    height: ${mbSize(434)};
  }
`

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  text-align: center;
  > img {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
  ${p =>
    p.isZoomOut &&
    css`
      ${TinyImg} {
        top: 1px;
      }
      ${ContentWrapper} {
        top: 33px;
      }
    `}
  ${Media.lessThan(Media.small)} {
    height: 650px;
  }
`

class PicturePriceDownBanner extends PureComponent {
  render() {
    this.isZoomOut = window ? window.devicePixelRatio >= 1.2 : false
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(relativePath: { regex: "/picture-price-down/bg.png/" }) {
              ...fluidImage
            }
            bannerMb: file(relativePath: { regex: "/picture-price-down/bg_mobile.png/" }) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb }) => (
          <BannerContainer isZoomOut={this.isZoomOut}>
            <TinyImg src={TinyImage} alt='' />
            <TinyMobileImg src={TinyMobileImage} alt='' />
            <CouponImg src={couponImage} alt='' />
            <ContentWrapper>
              <ContentImg src={ContentImage} alt='' />
              <ContentMobileImg src={ContentMobileImage} alt='' />
              <DetailButton id='index-xgt' as={Link} to='/effect_price.html'>
                了解详情
              </DetailButton>
            </ContentWrapper>
            <Billboard
              fluid={[
                bannerMb.childImageSharp.fluid,
                {
                  ...banner.childImageSharp.fluid,
                  media: `(min-width: 750px)`,
                },
              ]}
            />
          </BannerContainer>
        )}
      />
    )
  }
}
export default PicturePriceDownBanner
