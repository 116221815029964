import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'
import { Billboard } from '@renderbus/common/components'
import { Media, typography } from '@renderbus/common/theme'
import { graphql, Link, useStaticQuery } from 'gatsby'
import XGTBannerButton from '../../images/xgt1212/xgt-button.png'

export const XGTBannerContainer = styled.div`
  position: relative;
  padding-top: 120px;
  height: 100vh;
  color: white;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    padding-top: 90px;
    height: 370px;
    p {
      font-size: ${typography.textSmall};
    }
  }
`

export const XGTBannerTitle = styled(Img)`
  margin: auto;
  width: 870px;
  max-height: 370px;
  ${Media.lessThan(Media.small)} {
    width: 90%;
  }
`

export const XGTBannerLink = styled.a`
  position: relative;
  display: inline-flex;
  width: 136px;
  height: 49px;
  color: black;
  padding: 3px 3px 0 0;
  span {
    text-align: center;
    line-height: 46px;
    font-size: ${typography.h4};
    width: 100%;
    z-index: 1;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  ${Media.lessThan(Media.small)} {
    width: 104px;
    height: 37px;
    padding: 2px 2px 0 0;
    span {
      line-height: 35px;
    }
  }
`

export default React.memo(function XGTBanner({
  buttonText = '开通会员',
  buttonLink = 'https://task.renderbus.com/center/user/topUp',
  buttonTo = '',
  minHeight = '370px',
}) {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { regex: "/xgt1212/xgt-double-twelve-bg.png/" }) {
        ...fluidImage
      }
      bannerMobile: file(relativePath: { regex: "/xgt1212/xgt-double-twelve-mobile-bg.png/" }) {
        ...fluidImage
      }
      bannerTitle: file(relativePath: { regex: "/xgt1212/xgt-double-twelve-title.png/" }) {
        ...fluidImage
      }
    }
  `)
  return (
    <XGTBannerContainer style={{ minHeight }}>
      <XGTBannerTitle fluid={data.bannerTitle.childImageSharp.fluid} />
      {buttonTo ? (
        <XGTBannerLink as={Link} to={buttonTo}>
          <img src={XGTBannerButton} alt={buttonText} />
          <span>{buttonText}</span>
        </XGTBannerLink>
      ) : (
        <XGTBannerLink href={buttonLink} target='_blank' rel='noopener noreferrer'>
          <img src={XGTBannerButton} alt={buttonText} />
          <span>{buttonText}</span>
        </XGTBannerLink>
      )}
      <p>2020年12月9-31日</p>
      <Billboard
        fluid={[
          data.bannerMobile.childImageSharp.fluid,
          {
            ...data.banner.childImageSharp.fluid,
            media: `(min-width: 600px)`,
          },
        ]}
      />
    </XGTBannerContainer>
  )
})
