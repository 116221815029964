import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardTitle, CardMore, CardCover, CardFooter } from './atoms'
import CmsContentService from './getCoverImg'

var moment = require('moment')
class NewsCard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      thumbnail: CmsContentService.getCoverImg(this.props.content || this.props.tinyMceContent),
    }
  }
  render() {
    const { title, slug, date } = this.props
    const { thumbnail } = this.state
    const utc8Data = moment(date).format('YYYY-MM-DD')
    return (
      <Card>
        <CardCover>
          <a href={`/news/${slug}/`}>
            <img src={thumbnail} alt={title} />
          </a>
        </CardCover>
        <CardTitle>{title}</CardTitle>
        <CardFooter>
          <span>{utc8Data}</span>
          <CardMore href={`/news/${slug}/`}>{`了解更多 >`}</CardMore>
        </CardFooter>
      </Card>
    )
  }
}

NewsCard.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  tinyMceContent: PropTypes.string.isRequired,
}
export default NewsCard
