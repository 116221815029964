import React, { PureComponent } from 'react'
import styled from 'styled-components'
import Media from '@renderbus/common/theme/media'
import WorldMap from '../../images/aspera/WorldMap'

const BannerContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  height: calc(100vh - 220px);
  margin-top: 60px;
  z-index: 0;
`

const ContentBox = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${Media.lessThan(Media.small)} {
    position: static;
    margin-top: 120px;
    transform: none;
    margin-left: 0;
  }
`

const AsperaH1 = styled.h1`
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #ffffff;
  ${Media.lessThan(Media.small)} {
    font-size: 24px;
    text-align: center;
  }
`

const AsperaText = styled.div`
  max-width: 556px;
  font-size: 18px;
  color: #ffffff;
  ${Media.lessThan(Media.small)} {
    margin: 0 44px;
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    line-height: 27px;
    text-align: center;
  }
`

const AsperaButton = styled.a`
  display: inline-block;
  padding: 13px 38px;
  background: #13cb75;
  border-radius: 50px;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  line-height: 14px;
  margin-top: 35px;

  ${Media.lessThan(Media.small)} {
    display: block;
    width: fit-content;
    margin: 20px auto 55px;
  }
`

const AsperaMap = styled(WorldMap)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 953px;
  ${Media.lessThan(Media.small)} {
    display: block;
    width: calc(100% - 40px);
    position: static;
    transform: none;
    margin: 0 20px;
  }
`

class AsperaBanner extends PureComponent {
  render() {
    return (
      <BannerContainer>
        <ContentBox>
          <AsperaH1>IBM Aspera®软件 </AsperaH1>
          <AsperaText>
            企业长距离传输大型文件、海量数据集并快速移动大数据解决方案，速度可比FTP快1000倍！
          </AsperaText>
          <AsperaButton href='/aspera.html'>申请免费体验</AsperaButton>
        </ContentBox>
        <AsperaMap />
      </BannerContainer>
    )
  }
}
export default AsperaBanner
