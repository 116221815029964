import { stripUnit } from 'polished'
import styled from 'styled-components'
import { H2 } from '@renderbus/common/components'
import { color, transition, Media, spacing, typography, shadows } from '@renderbus/common/theme'

export const CardTitle = styled(H2)`
  line-height: 1;
  border-left: 5px solid ${color.primary};
  text-indent: ${`${stripUnit(spacing.large) - 5}px`};
  ${Media.lessThan(Media.small)} {
    border: none;
    text-indent: 0;
    text-align: center;
    font-size: ${typography.h3};
  }
`
export const CardContent = styled.div`
  height: 160px;
  padding: ${spacing.large} 0;
  ${Media.lessThan(Media.small)} {
    position: absolute;
    padding: 1.25em 0 1em;
    bottom: 0;
    width: 100%;
    height: 80px;
    background-color: rgba(0, 0, 0, 0.7);
  }
`
export const CardSubTitle = styled.p`
  color: ${color.prompt};
  font-size: ${typography.text};
  text-indent: ${spacing.large};
  ${Media.lessThan(Media.small)} {
    border: none;
    text-indent: 0;
    text-align: center;
    margin: 8px 0;
  }
`
export const CardAction = styled.div`
  position: absolute;
  width: 100%;
  height: 160px;
  bottom: -100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 0 ${spacing.large};
  background-color: ${color.primary};
  transition: ${transition('bottom')};
  > p {
    margin: 0;
  }
  ${Media.lessThan(Media.small)} {
    height: 100%;
    padding: 0 1em;
  }
`
export const Card = styled.div`
  overflow: hidden;
  position: relative;
  box-shadow: ${shadows[1]};
  background-color: ${color.card};
  transition: ${transition('box-shadow')};
  :hover {
    box-shadow: ${shadows[6]};
    ${CardAction} {
      bottom: 0;
    }
  }
`
