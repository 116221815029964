import React from 'react'
import styled from 'styled-components'
import { Billboard } from '@renderbus/common/components'
import { Media, typography } from '@renderbus/common/theme'
import { graphql, useStaticQuery } from 'gatsby'
import Year2022Img from '../../images/banner/annual-report/2022.png'
import QrCode01 from '../../images/banner/annual-report/qr-code1.png'

export const AnnualReportContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: white;
  ${Media.lessThan(Media.small)} {
    padding: 100px 0 200px;
    align-items: flex-start;
    text-align: center;
  }
`

export const AnnualReportContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  width: 1300px;
  padding-bottom: 100px;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    height: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 0;
  }
`

export const AnnualTitle = styled.div`
  font-size: 68px;
  line-height: 88px;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: #4fff6f;
  ${Media.lessThan(Media.small)} {
    font-size: 37px;
    text-align: center;
    line-height: 46px;
    justify-content: center;
    img {
      width: 72px;
      height: 35px;
    }
  }
`

export const AnnualBannerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 40px;
  font-weight: 500;
  font-size: ${typography.h3};
  width: 548px;
  height: 60px;
  border: 2px solid #ffffff;
  border-radius: 10px 10px 10px 0px;
  color: white;
  letter-spacing: 10px;
  ${Media.lessThan(Media.small)} {
    margin: 10px auto 0;
    width: 300px;
    height: 30px;
    font-size: ${typography.text};
    border-radius: 5px 5px 5px 0px;
    border: 1px solid #ffffff;
    letter-spacing: 5px;
  }
`

export const QrCodeImg = styled.div`
  width: 160px;
  height: 160px;
  background: #ffffff;
  border: 2px solid #4efb6d;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 36px;
  position: relative;
  img {
    width: 142px;
    height: 142px;
  }
  span {
  }
  ${Media.lessThan(Media.small)} {
    width: 90px;
    height: 90px;
    margin: 0 auto 30px;
    img {
      width: 80px;
      height: 80px;
    }
  }
`
export const GreenText = styled.div`
  color: #4efb6d;
  display: inline-block;
  width: 10px;
  position: absolute;
  right: -20px;
  font-size: 20px;
  ${Media.lessThan(Media.small)} {
    display: block;
    width: 100%;
    bottom: -25px;
    font-size: ${typography.textSmall};
    right: unset;
  }
`

export const AnnualDate = styled.div`
  font-size: ${typography.h4};
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.textThin};
  }
`

export default React.memo(function AnnualReportBanner() {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { regex: "/banner/annual-report/annual-bg.png/" }) {
        ...fluidImage
      }
      bannerMb: file(relativePath: { regex: "/banner/annual-report/annual-bg-mb.png/" }) {
        ...fluidImage
      }
    }
  `)
  return (
    <AnnualReportContainer>
      <AnnualReportContent>
        <div>
          <AnnualTitle>
            我的
            <img src={Year2022Img} alt='' />
          </AnnualTitle>
          <AnnualTitle>瑞云渲染年度报告</AnnualTitle>
          <AnnualBannerButton>一分钟回顾全年渲染历程</AnnualBannerButton>
        </div>
        <div>
          <QrCodeImg>
            <img src={QrCode01} alt='' className='qrcode1' />
            <GreenText>扫码查看</GreenText>
          </QrCodeImg>
          <AnnualDate>活动时间：2022年12月29日～2023年2月28日</AnnualDate>
        </div>
      </AnnualReportContent>
      <Billboard
        fluid={[
          data.banner.childImageSharp.fluid,
          { ...data.bannerMb.childImageSharp.fluid, media: `(max-width: 780px)` },
        ]}
      />
    </AnnualReportContainer>
  )
})
