import { rgba } from 'polished'
import styled, { css } from 'styled-components'
import { Row, Column, Flex } from '@renderbus/common/components'
import {
  color,
  transition,
  duration,
  hideSMDown,
  hideSMUp,
  typography,
} from '@renderbus/common/theme'

export const Cycle = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  ::after {
    content: '';
    position: absolute;
    top: 12px;
    left: 12px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${color.primary};
    transition: ${transition('box-shadow', duration.complex)};
  }
  ::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transform: scale(0);
    transition: ${transition('transform', duration.complex)};
    background-color: ${rgba(color.primary, 0.2)};
  }
  ${({ active }) =>
    active &&
    css`
      ::after {
        box-shadow: 0 0 0 12px ${rgba(color.primary, 0.08)};
      }
      ::before {
        transform: scale(1);
      }
    `}
`
export const Tooltips = styled.div`
  margin-left: 8px;
  position: relative;
  color: white;
  height: 40px;
  line-height: 40px;
  padding: 0 1em;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  ::before {
    content: '';
    position: absolute;
    top: 12px;
    left: -16px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent rgba(0, 0, 0, 0.3) transparent transparent;
  }
`
export const Container = styled.div`
  height: 240px;
  position: relative;
  ${hideSMDown};
`
export const MoblieContainer = styled(Row)`
  height: 100px;
  ${hideSMUp};
`
export const Sparkles = styled(Column)`
  color: white;
  text-align: center;
  font-size: ${typography.textSmall};
`
export const SparklesItem = styled(Flex)`
  justify-content: flex-start;
  ${Cycle} {
    width: 20px;
    height: 20px;
    ::after {
      width: 6px;
      height: 6px;
      top: 5px;
      left: 5px;
      box-shadow: 0 0 0 6px ${rgba(color.primary, 0.08)};
    }
    ::before {
      width: 12px;
      height: 12px;
      top: 2px;
      left: 2px;
    }
  }
`
