import React, { useRef, useState } from 'react'
import { H1, H4, Modal } from '@renderbus/common/components'
import { useInViewport } from '@renderbus/common/hooks'

import { VideoWrap, PlayButton } from './atoms'

function CaseVideo() {
  const videoRef = useRef(null)
  const [isOverview, setIsOverview] = useState(false)
  //@see https://stackoverflow.com/questions/20347352/html5-video-tag-not-working-in-safari-iphone-and-ipad#answer-54320172
  const onEnter = () => videoRef.current && videoRef.current.play()
  const onLeave = () => videoRef.current && videoRef.current.pause()
  const [, setTargetRef] = useInViewport({ onEnter, onLeave })
  return (
    <VideoWrap ref={setTargetRef}>
      <PlayButton
        title='全屏播放'
        onClick={() => {
          onLeave()
          setIsOverview(true)
        }}
      />
      <div>
        <H1 as='h2'>影视/视效/动画云渲染</H1>
        <H4>
          影视视效,CG动画,建筑可视化,商业广告,都使用瑞云渲染
          <br />
          我们服务的项目如繁星般璀璨
        </H4>
      </div>
      <video autoPlay={true} muted loop playsInline={true} ref={videoRef}>
        <source
          type='video/mp4'
          src='https://renderbus-assets.oss-cn-hangzhou.aliyuncs.com/video/renderbus-case-version20210207.mp4'
          rel='nofollow'
        />
      </video>
      <Modal
        simpleHeader
        bodyStyle={{ padding: '0' }}
        visible={isOverview}
        onClose={() => {
          onLeave()
          setIsOverview(false)
        }}
        darkTheme={true}
      >
        {isOverview && (
          <video
            src='https://renderbus-assets.oss-cn-hangzhou.aliyuncs.com/video/new-renderbus-20210205.mp4'
            rel='nofollow'
            controls={true}
            autoPlay={true}
            allowFullScreen={true}
            style={{ width: '80vw' }}
          />
        )}
      </Modal>
    </VideoWrap>
  )
}

export default CaseVideo
