import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Billboard, Flex } from '@renderbus/common/components'
import { Media, spacing, typography } from '@renderbus/common/theme'
import { graphql, StaticQuery } from 'gatsby'
import TitleImg from '../../images/index/start-new-year-title.png'
import BannerButton from '../../images/index/start-new-year-detail.png'
import BannerButton2 from '../../images/index/click-right-now.png'

const BannerContainer = styled.div`
  position: relative;
  height: calc(100vh - 60px);
  margin-top: 60px;
  text-align: center;
  padding-bottom: 160px;
  button,
  a {
    margin-top: ${spacing.base};
  }
  .start-new-year-activity-time {
    font-size: 18px;
    color: #b22f17;
    margin-top: 18px;
    font-weight: bold;
  }
  ${Media.lessThan(Media.small)} {
    button,
    a {
      width: 160px;
      font-size: ${typography.h4};
    }
    .start-new-year-content {
      padding: 25px;
      img {
        width: 100%;
      }
      a {
        margin-top: 15px;
      }
      .start-new-year-activity-time {
        margin-top: 10px;
        font-size: 15px;
      }
    }
  }
`

class StartNewYearBanner extends PureComponent {
  render() {
    const { inPage = false } = this.props
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(relativePath: { regex: "/index/start-new-year-index-banner.png/" }) {
              ...fluidImage
            }
            bannerMb: file(relativePath: { regex: "/index/start-new-year-banner-mb.png/" }) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb }) => (
          <BannerContainer>
            <Flex direction='column' style={{ height: '100%' }} className='start-new-year-content'>
              <img src={TitleImg} alt='新春开工大放送' />
              <a
                href={
                  inPage ? 'https://task.renderbus.com/center/user/topUp' : '/start-new-year.html'
                }
                id='xgtxckg'
              >
                <img
                  src={inPage ? BannerButton2 : BannerButton}
                  alt={inPage ? '立即充值' : '查看详情'}
                />
              </a>
              <div className='start-new-year-activity-time'>活动时间：2022.02.10—2022.03.10</div>
            </Flex>
            <Billboard
              fluid={[
                bannerMb.childImageSharp.fluid,
                {
                  ...banner.childImageSharp.fluid,
                  media: `(min-width: 780px)`,
                },
              ]}
              alt='新春开工大放送'
            />
          </BannerContainer>
        )}
      />
    )
  }
}
export default StartNewYearBanner
