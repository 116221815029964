import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { hideSMDown, hideSMUp, Media, spacing, typography } from '@renderbus/common/theme'
import { Billboard } from '@renderbus/common/components'
import { useLocation } from '@reach/router'

import TitleImage from '../../images/banner/year-end-recharge-2021-banner/year-end-recharge-title.png'
import TitleMobileImage from '../../images/banner/year-end-recharge-2021-banner/year-end-recharge-title-mobile.png'
import PrizeImage from '../../images/banner/year-end-recharge-2021-banner/year-end-recharge-prize.png'

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  padding-bottom: 35px;
`
const BannerFlex = styled.div`
  width: 1500px;
  max-width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  height: 100%;
  img {
    max-width: 100%;
  }
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    justify-content: center;
  }
`
const BannerContent = styled.div`
  padding: 120px ${spacing.small} 0 70px;
  flex-shrink: 0;
  height: 602px;
  ${Media.lessThan(Media.small)} {
    padding: 70px ${spacing.base} 0;
    text-align: center;
    height: auto;
  }
`
const LimitTip = styled.div`
  margin-bottom: ${spacing.large};
  width: 300px;
  height: 44px;
  line-height: 42px;
  border: 2px solid #ffd2ab;
  border-radius: 22px;
  background: linear-gradient(0deg, #fad8a9 0%, #efcbad 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: ${typography.h3};
  text-align: center;
  ${Media.lessThan(Media.small)} {
    margin: 0 auto ${spacing.large};
    height: 30px;
    line-height: 28px;
    width: 200px;
    font-size: ${typography.text};
  }
`
const TimeText = styled.p`
  margin: ${spacing.large} 0 ${spacing.base};
  color: white;
  font-size: ${typography.h4};
  ${Media.lessThan(Media.small)} {
    margin: ${spacing.base} 0;
    font-size: ${typography.text};
  }
`
const RechargeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 46px;
  background: linear-gradient(0deg, #ffa454 0%, #ffd2ab 100%);
  border-radius: 23px;
  color: black;
  outline: none;
  border: none;
  font-size: ${typography.h4};
  font-weight: 500;
  line-height: 1;
  ${Media.lessThan(Media.small)} {
    margin: 0 auto ${spacing.large};
    width: 120px;
    height: 34px;
    font-size: ${typography.text};
    color: #191919;
  }
`
const MobileImg = styled.img`
  ${hideSMUp}
`
const PCImg = styled.img`
  ${hideSMDown}
`

const YearEndRecharge2021Banner = React.memo(function() {
  const data = useStaticQuery(graphql`
    query {
      banner: file(
        relativePath: { eq: "banner/year-end-recharge-2021-banner/year-end-recharge-bg.png" }
      ) {
        ...fluidImage
      }
      bannerMobile: file(
        relativePath: { eq: "banner/year-end-recharge-2021-banner/year-end-recharge-bg-mobile.png" }
      ) {
        ...fluidImage
      }
    }
  `)
  const location = useLocation()
  const isIndex = location.pathname === '/'
  return (
    <BannerContainer>
      <BannerFlex>
        <BannerContent>
          <LimitTip>影视动画渲染用户专享</LimitTip>
          <PCImg src={TitleImage} alt='年终渲染充值送豪礼！RTX专业显卡送你！' />
          <MobileImg src={TitleMobileImage} alt='年终渲染充值送豪礼！RTX专业显卡送你！' />
          <TimeText>11月19日-12月29日充值，12月30日开奖</TimeText>
          {isIndex ? (
            <RechargeButton as={Link} to='/ysdh-year-end-recharge.html' id='ban-ysdhnz'>
              查看详情
            </RechargeButton>
          ) : (
            <RechargeButton
              as='a'
              href='https://account.renderbus.com/center/user/topUp'
              target='_blank'
              id='ysdhnz-ljcz-ban'
            >
              立即充值
            </RechargeButton>
          )}
        </BannerContent>
        <img src={PrizeImage} alt='prize' />
      </BannerFlex>
      <Billboard
        fluid={[
          data.bannerMobile.childImageSharp.fluid,
          {
            ...data.banner.childImageSharp.fluid,
            media: `(min-width: 780px)`,
          },
        ]}
      />
    </BannerContainer>
  )
})

export default YearEndRecharge2021Banner
