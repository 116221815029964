import React from 'react'
import { Row, Column } from '@renderbus/common/components'
import NewsCard from './news-card'

class NewsCardList extends React.PureComponent {
  render() {
    const { newsList } = this.props
    return (
      <Row spacing={3} style={{ width: '100%', margin: '0px' }}>
        {newsList.map((news, index) => {
          return (
            <Column xs='12' lg='4' key={index}>
              <NewsCard
                content={news.content ? news.content.content : ''}
                tinyMceContent={news.tinyMceContent ? news.tinyMceContent.tinyMceContent : ''}
                title={news.title}
                slug={news.slug}
                date={news.legacyDate || news.createdAt}
              ></NewsCard>
            </Column>
          )
        })}
      </Row>
    )
  }
}

export default NewsCardList
