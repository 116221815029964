import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Billboard } from '@renderbus/common/components'
import { color, Media, spacing, typography } from '@renderbus/common/theme'
import { graphql, StaticQuery } from 'gatsby'

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`
const BannerTitleContent = styled.div`
  background: linear-gradient(0deg, #13cb75 0%, #13cb91 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const IPTitle = styled.p`
  margin: 0;
  font-size: 60px;
  line-height: 1;
  font-weight: bold;
  ${Media.lessThan(Media.small)} {
    font-size: 30px;
  }
`

const IPBr = styled.div`
  margin: 36px auto ${spacing.large};
  width: 120px;
  height: 6px;
  background-color: ${color.primary};
  ${Media.lessThan(Media.small)} {
    margin: ${spacing.base} auto 17px;
    width: 60px;
    height: 3px;
  }
`

const IPSubTitle = styled.p`
  font-size: 30px;
  font-weight: bold;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h4};
  }
`

const AdvantageContainer = styled.div`
  margin: ${spacing.large} auto 48px;
  ${Media.lessThan(Media.small)} {
    margin: 25px 0;
  }
`

const AdvantageContent = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0 ${spacing.large};
  margin: 0 15px;
  height: 45px;
  border: 1px solid white;
  color: white;
  font-size: 20px;
  font-weight: 400;
  ${Media.lessThan(Media.small)} {
    margin: 25px 0;
    font-size: ${typography.textSmall};
    height: 30px;
    padding: 0 ${spacing.base};
    margin: 0 ${spacing.small};
  }
`

const IPButton = styled.a`
  padding: 0 2em;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  color: white;
  background-color: ${color.primary};
  font-size: 20px;
  border-radius: 25px;
  user-select: none;
  font-weight: 500;
  ${Media.lessThan(Media.small)} {
    height: 35px;
    line-height: 35px;
    font-size: ${typography.text};
  }
`

class IPBanner extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(relativePath: { eq: "banner/ip-banner/ip-banner.png" }) {
              ...fluidImage
            }
            bannerMb: file(relativePath: { eq: "banner/ip-banner/ip-mobile-banner.png" }) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb }) => (
          <BannerContainer>
            <div>
              <BannerTitleContent>
                <IPTitle>瑞云版权登记平台</IPTitle>
                <IPBr />
                <IPSubTitle>权威版权登记 | 原创作品的身份证明</IPSubTitle>
              </BannerTitleContent>
              <AdvantageContainer>
                <AdvantageContent>在线申请</AdvantageContent>
                <AdvantageContent>官方出证</AdvantageContent>
                <AdvantageContent>高效便捷</AdvantageContent>
              </AdvantageContainer>
              <IPButton href='https://ip.renderbus.com' target='blank'>
                版权登记50元起
              </IPButton>
            </div>
            <Billboard
              fluid={[
                bannerMb.childImageSharp.fluid,
                {
                  ...banner.childImageSharp.fluid,
                  media: `(min-width: 600px)`,
                },
              ]}
            />
          </BannerContainer>
        )}
      />
    )
  }
}
export default IPBanner
