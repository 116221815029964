import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Billboard, Button, Flex } from '@renderbus/common/components'
import { Media } from '@renderbus/common/theme'
import { graphql, Link, StaticQuery } from 'gatsby'

import EdcationDiscountPlanTextImg from '../../images/educate-disacount-plan/educate-title.png'

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    margin-top: 60px;
    height: 650px;
  }
`
const EducationDiscountPlanContent = styled(Flex)`
  flex-direction: column;
  height: calc(100vh - 220px);
  ${Media.lessThan(Media.small)} {
    padding-top: 164px;
    height: auto;
  }
`
const EducationDiscountPlanText = styled.div`
  background: ${`url(${EdcationDiscountPlanTextImg})`} no-repeat center center;
  height: 334px;
  z-index: 2;
  width: 100%;
  margin: 60px auto 5px;
  max-width: 1400px;
  ${Media.lessThan(Media.small)} {
    margin-bottom: 16px;
    height: 153px;
    width: 350px;
    background-size: 222px 111px;
  }
`
const ToDetailBtn = styled(Button)`
  width: 140px;
  height: 40px;
  line-height: 40px;
  background: #ffffff;
  border-radius: 27px;
  color: #14cc76;
  ${Media.lessThan(Media.small)} {
    width: 120px;
    height: 35px;
    line-height: 35px;
  }
`

class EducationDiscountPlanBanner extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(
              relativePath: { regex: "/educate-disacount-plan/educate-disacount-plan-bg.png/" }
            ) {
              ...fluidImage
            }
            bannerMb: file(
              relativePath: { regex: "/educate-disacount-plan/educate-disacount-plan-mbg.png/" }
            ) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb }) => (
          <BannerContainer>
            <EducationDiscountPlanContent>
              <EducationDiscountPlanText />
              <ToDetailBtn as={Link} to='/gocloud.html' id='jyyh-ckxq'>
                查看详情
              </ToDetailBtn>
            </EducationDiscountPlanContent>
            <Billboard
              fluid={[
                bannerMb.childImageSharp.fluid,
                {
                  ...banner.childImageSharp.fluid,
                  media: `(min-width: 780px)`,
                },
              ]}
            />
          </BannerContainer>
        )}
      />
    )
  }
}
export default EducationDiscountPlanBanner
