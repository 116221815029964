import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Billboard, Button, Flex } from '@renderbus/common/components'
import { Media } from '@renderbus/common/theme'
import { graphql, StaticQuery } from 'gatsby'

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    margin-top: 60px;
    height: 650px;
  }
`
const NewUserCouponBannerContent = styled(Flex)`
  flex-direction: column;
  padding-top: 372px;
  align-items: flex-start;
  margin-left: 17.13vw;
  ${Media.lessThan(Media.small)} {
    padding-top: 20.314vh;
    align-items: center;
    margin: unset;
  }
`
const NewUserCouponBannerTitle = styled.div`
  display: flex;
  font-size: 64px;
  font-weight: 800;
  color: #ffffff;
  line-height: 64px;
  .green {
    color: #13cb75;
  }
  ${Media.lessThan(Media.small)} {
    font-size: 8.53vw;
    line-height: 8.53vw;
  }
`
const NewUserCouponBannerTip = styled.div`
  font-size: 36px;
  font-weight: 400;
  color: #fefefe;
  line-height: 36px;
  margin-top: 22px;
  margin-bottom: 30px;
  text-shadow: 2px 2px 3px rgba(34, 34, 34, 0.5);
  ${Media.lessThan(Media.small)} {
    font-size: 4.8vw;
    line-height: 4.8vw;
    margin-top: 2.24vh;
    margin-bottom: 2.99vh;
  }
`
const ToGegisterBtn = styled(Button)`
  width: 152px;
  height: 38px;
  background: #13cb75;
  border-radius: 19px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 38px;
  ${Media.lessThan(Media.small)} {
    width: 33.33vw;
    height: 10.66vw;
    line-height: 10.66vw;
    font-size: 3.73vw;
  }
`

class NewUserCouponBnaner extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(relativePath: { regex: "/new-user-coupon/bg.png/" }) {
              ...fluidImage
            }
            bannerMb: file(relativePath: { regex: "/new-user-coupon/bg_mobile.png/" }) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb }) => (
          <BannerContainer>
            <NewUserCouponBannerContent>
              <NewUserCouponBannerTitle>
                <span>新用户</span>
                <span className='green'>超值福利</span>
              </NewUserCouponBannerTitle>
              <NewUserCouponBannerTip>注册即送无门槛渲染券享免费渲染</NewUserCouponBannerTip>
              <ToGegisterBtn as='a' href='https://task.renderbus.com/sso/register'>
                立即注册
              </ToGegisterBtn>
            </NewUserCouponBannerContent>
            <Billboard
              fluid={[
                bannerMb.childImageSharp.fluid,
                {
                  ...banner.childImageSharp.fluid,
                  media: `(min-width: 780px)`,
                },
              ]}
            />
          </BannerContainer>
        )}
      />
    )
  }
}
export default NewUserCouponBnaner
