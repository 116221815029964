import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Billboard, Button, Flex } from '@renderbus/common/components'
import { Media } from '@renderbus/common/theme'
import { graphql, Link, StaticQuery } from 'gatsby'
import { hideSMDown } from '@renderbus/common/theme/mixin'

import MidYearRechargeTextImg from '../../images/xgt-mid-year-recharge/midyear-2022-title.png'
import MidYearRechargeDateBackImg from '../../images/xgt-mid-year-recharge/midyear-2022-date-back.png'

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    margin-top: 60px;
    height: ${p => p.mbHeight};
  }
`
const YearendRechargeContent = styled(Flex)`
  flex-direction: column;
  margin-top: 60px;
  width: 100%;
  p {
    color: #fff;
  }
`
const YearendRechargeText = styled.img`
  margin-top: 35px;
  content: ${`url(${MidYearRechargeTextImg})`};
  width: 643px;
  z-index: 2;
  ${hideSMDown};
`
const ToDetailBtn = styled(Button)`
  width: 150px;
  height: 46px;
  margin-top: 61px;
  line-height: 46px;
  background: #f9fefc;
  border-radius: 23px;
  color: #5db083;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    width: 120px;
    height: 35px;
    position: absolute;
    bottom: ${p => (p.isBanner ? '245px' : '220px')};
    line-height: 35px;
  }
`

const MidYearRechargeTitle = styled.div`
  border: 1px solid #fecb8b;
  border-radius: 26px;
  width: 189px;
  height: 52px;
  margin-top: 88px;
  p,
  h1 {
    font-size: 24px;
    height: 28px;
    line-height: 50px;
    color: #fecb8b;
    text-shadow: 1px 1px 8px rgba(12, 14, 18, 0.3);
    font-weight: 400;
    margin: 0;
  }
  ${hideSMDown};
`
const MidYearRechargeTime = styled.div`
  font-size: 21px;
  color: #2eb878;
  text-shadow: 3px 4px 8px #0c0e12;
  background: ${`url(${MidYearRechargeDateBackImg})`} no-repeat center center;
  padding: 20px;
  display: flex;
  margin-top: 32px;
  font-weight: 400;
  ${hideSMDown};
`

class MidYearRecharge2022XgtBanner extends PureComponent {
  render() {
    const {
      buttonLabel = '点击查看',
      buttonLink = '/nzjh-czhd.html',
      buttonID = 'nzjh-djck',
      buttonHref = '',
      mbHeight = '650px',
      isBanner = true,
    } = this.props
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(relativePath: { regex: "/xgt-mid-year-recharge/midyear-2022-bg.png/" }) {
              ...fluidImage
            }
            bannerMb: file(
              relativePath: { regex: "/xgt-mid-year-recharge/midyear-2022-mobile-bg.png/" }
            ) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb }) => (
          <BannerContainer mbHeight={mbHeight}>
            <YearendRechargeContent>
              <MidYearRechargeTitle>
                {isBanner && <p>效果图专享</p>}
                {!isBanner && <h1>效果图专享</h1>}
              </MidYearRechargeTitle>
              <YearendRechargeText />
              <MidYearRechargeTime>2022年6月15日&nbsp;~&nbsp;2022年7月15日</MidYearRechargeTime>
              <ToDetailBtn
                as={buttonHref ? 'a' : Link}
                href={buttonHref}
                to={buttonLink}
                id={buttonID}
                isBanner={isBanner}
              >
                {buttonLabel}
              </ToDetailBtn>
            </YearendRechargeContent>
            <Billboard
              fluid={[
                bannerMb.childImageSharp.fluid,
                {
                  ...banner.childImageSharp.fluid,
                  media: `(min-width: 600px)`,
                },
              ]}
            />
          </BannerContainer>
        )}
      />
    )
  }
}
export default MidYearRecharge2022XgtBanner
