import React from 'react'
import { Flex, H1, Billboard } from '@renderbus/common/components'
import { useStaticQuery, graphql } from 'gatsby'
import { BannerContainer, Emphasis } from './atom'
import PhoneCombo from '../phone-combo'

const CloudRenderBanner = () => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "index/bg.png" }) {
        ...fluidImage
      }
      bannerMb: file(relativePath: { eq: "index/bg-mobile.png" }) {
        ...fluidImage
      }
    }
  `)
  return (
    <BannerContainer>
      <Flex direction='column' style={{ height: '80%' }}>
        <H1>
          <Emphasis>Renderbus</Emphasis> 云渲染农场 <br /> 您强大的云渲染部队
        </H1>
        <PhoneCombo width='420px' style={{ margin: '2em 0' }} registerText='免费注册领福利' />
      </Flex>
      <Billboard
        fluid={[
          data.bannerMb.childImageSharp.fluid,
          {
            ...data.banner.childImageSharp.fluid,
            media: `(min-width: 780px)`,
          },
        ]}
        alt='瑞云Renderbus渲染农场-云渲染客户端界面'
      />
    </BannerContainer>
  )
}

export default CloudRenderBanner
