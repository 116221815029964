import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Billboard, Button, Flex } from '@renderbus/common/components'
import { Media, typography, color, spacing } from '@renderbus/common/theme'
import { graphql, StaticQuery } from 'gatsby'
import CardBg from '../../images/index/card-bg.png'
import CardMbBg from '../../images/index/card-mobile-bg.png'
import TitleLeft from '../../images/index/title-left.png'
import TitleRight from '../../images/index/title-right.png'
import Icon1 from '../../images/index/icon-discount.png'
import Icon2 from '../../images/index/icon-price.png'
import Icon3 from '../../images/index/icon-layers.png'

export const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    padding-top: 100px;
  }
`
export const BannerTitle = styled.div`
  display: flex;
  justify-content: center;
  font-size: 60px;
  p {
    padding-left: ${spacing.base};
    font-weight: 800;
    background: linear-gradient(0deg, #dc9d5f 0%, #e8c89d 54.736328125%, #f7e2c0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  img {
    object-fit: none;
    width: 332px;
  }
  ${Media.lessThan(Media.small)} {
    img {
      display: none;
    }
    p {
      width: 70%;
      font-size: ${typography.title};
    }
  }
`
export const CardWrapper = styled.div`
  width: 1081px;
  height: 334px;
  background-image: ${`url(${CardBg})`};
  background-size: contain;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 54px;
  ${Media.lessThan(Media.small)} {
    background-image: ${`url(${CardMbBg})`};
    width: 90%;
    background-repeat: no-repeat;
  }
`
export const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #e4bd8d;
  font-size: ${typography.h3};
  img {
    margin: -40px 0 50px 0;
    height: 62px;
    object-fit: contain;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.textThin};
    img {
      margin: -200px 0 20px 0;
      height: 45px;
    }
  }
`

class EnterpriseBanner extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(relativePath: { regex: "/index/banner-enterprise.png/" }) {
              ...fluidImage
            }
          }
        `}
        render={({ banner }) => (
          <BannerContainer>
            <Flex direction='column' style={{ height: '90%' }}>
              <BannerTitle>
                <img src={TitleLeft} alt='' />
                <p>一键企业认证，开启专属权益！</p>
                <img src={TitleRight} alt='' />
              </BannerTitle>
              <Button
                id='ban-qyrz'
                onClick={() =>
                  window.open('https://task.renderbus.com/center/user/profile', '_blank')
                }
                style={{
                  color: color.background,
                  fontSize: '20px',
                  background: 'linear-gradient(-90deg, #DC9D5F, #E8C89D, #F7E2C0)',
                }}
              >
                一键认证
              </Button>
              <CardWrapper>
                <Item>
                  <img src={Icon1} alt='' />
                  <span>渲染价格更优惠</span>
                </Item>
                <Item>
                  <img src={Icon2} alt='' />
                  <span>渲染额度更高</span>
                </Item>
                <Item>
                  <img src={Icon3} alt='' />
                  <span>主子账户管理更便捷</span>
                </Item>
              </CardWrapper>
            </Flex>
            <Billboard fluid={banner.childImageSharp.fluid} alt='enterprise' />
          </BannerContainer>
        )}
      />
    )
  }
}
export default EnterpriseBanner
