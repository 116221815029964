import styled from 'styled-components'
import { Flex, H1, H4 } from '@renderbus/common/components'
import { typography, Media, color, transition } from '@renderbus/common/theme'
import { rgba } from 'polished'

export const trivia = styled.p`
  color: white;
  font-size: ${typography.h4};
`

export const VideoWrap = styled(Flex)`
  height: 720px;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  color: white;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.75) 3px 0 3px;
  > video {
    z-index: -1;
    object-fit: cover;
    position: absolute;
    ::-webkit-media-controls-play-button {
      display: none !important;
    }
  }
  ${Media.lessThan(Media.small)} {
    height: 360px;
    ${H1} {
      font-size: ${typography.h3};
    }
    ${H4} {
      font-size: ${typography.textThin};
    }
    > video {
      transform: scale(0.5);
    }
  }
`
export const PlayButton = styled.div`
  cursor: pointer;
  width: 80px;
  height: 80px;
  margin-top: -80px;
  margin-bottom: 80px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid currentColor;
  transition: ${transition(['color', 'background-color'])};
  :hover {
    color: ${color.primary};
    background-color: ${rgba(color.primary, 0.1)};
  }
  ::after {
    display: block;
    content: '';
    width: 0;
    height: 0;
    margin-right: -5px;
    border-width: 15px 0 15px 24px;
    border-style: solid;
    border-color: currentColor;
    border-top-color: transparent;
    border-bottom-color: transparent;
  }
  ${Media.lessThan(Media.small)} {
    transform: scale(0.7);
  }
`
