import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Billboard, Flex } from '@renderbus/common/components'
import { Media, mbSize } from '@renderbus/common/theme'
import { graphql, Link, StaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    margin-top: 60px;
    height: ${mbSize(1334)};
  }
`
const YearendRechargeContent = styled(Flex)`
  flex-direction: column;
  margin-top: 60px;
  width: 100%;
  p {
    color: #fff;
  }
  ${Media.greaterThan(Media.xxxlarge)} {
    padding-top: 30px;
  }
`
const ToDetailBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 180px;
  height: 46px;
  background: #2689ff;
  font-size: 20px;
  color: #fdfeff;
  margin-top: 47px;
  border-radius: 23px;
  &:hover {
    box-shadow: 0px 7px 6px 0px rgba(19, 100, 197, 0.46);
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(180)};
    height: ${mbSize(46)};
    font-size: ${mbSize(24)};
    margin-top: ${mbSize(52)};
  }
`

const MidYearRechargeTime = styled.div`
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  line-height: 22px;
  display: flex;
  z-index: 2;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(24)};
    line-height: ${mbSize(22)};
  }
`
const ContentImage = styled(GatsbyImage)`
  width: 577px;
  height: 282px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(577)};
    height: ${mbSize(282)};
  }
`
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 360px;
  height: 44px;
  border-radius: 22px;
  border: 2px solid #fdfeff;
  margin: 70px 0 18px 0;
  font-size: 22px;
  color: #fdfeff;
  line-height: 22px;
  letter-spacing: 2px;
  .diamond {
    width: 20px;
    height: 19px;
    margin-right: 13px;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(403)};
    height: ${mbSize(46)};
    font-size: ${mbSize(24)};
    color: #fdfeff;
    letter-spacing: ${mbSize(2)};
    border-radius: ${mbSize(22)};
    border: ${mbSize(2)} solid #fdfeff;
    .diamond {
      width: ${mbSize(20)};
      height: ${mbSize(19)};
      margin-right: ${mbSize(7)};
    }
  }
`

class MidYearRecharge2024XgtBanner extends PureComponent {
  render() {
    const {
      buttonLink = '/nzjh-czhd.html',
      buttonID = 'nzjh-djck',
      buttonHref = '',
      isBanner = true,
    } = this.props
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(relativePath: { regex: "/xgt-mid-year-recharge/2024/bg-adjust.png/" }) {
              ...fluidImage
            }
            button: file(
              relativePath: { regex: "/xgt-mid-year-recharge/2024/banner-button.png/" }
            ) {
              ...fluidImage
            }
            buttonHover: file(
              relativePath: { regex: "/xgt-mid-year-recharge/2024/banner-button-hover.png/" }
            ) {
              ...fluidImage
            }
            bannerMb: file(relativePath: { regex: "/xgt-mid-year-recharge/2024/bg-mb.png/" }) {
              ...fluidImage
            }
            bannerPage: file(
              relativePath: { regex: "/xgt-mid-year-recharge/2024/bg-page-banner.png/" }
            ) {
              ...fluidImage
            }
            content: file(relativePath: { regex: "/xgt-mid-year-recharge/2024/content.png/" }) {
              ...fluidImage
            }
            diamond: file(relativePath: { regex: "/xgt-mid-year-recharge/2024/diamond.png/" }) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb, content, diamond, bannerPage, buttonHover, button }) => (
          <BannerContainer>
            <YearendRechargeContent>
              <TitleWrapper>
                <GatsbyImage fluid={diamond.childImageSharp.fluid} className='diamond' />
                瑞云效果图充值福利享不停
              </TitleWrapper>
              <ContentImage fluid={[content.childImageSharp.fluid]} />
              <MidYearRechargeTime>{`活动时间：2024年6月7日-7月7日`}</MidYearRechargeTime>
              <ToDetailBtn
                as={buttonHref ? 'a' : Link}
                href={buttonHref}
                to={buttonLink}
                id={buttonID}
                isBanner={isBanner}
              >
                查看详情
              </ToDetailBtn>
            </YearendRechargeContent>
            <Billboard
              fluid={[
                bannerMb.childImageSharp.fluid,
                {
                  ...banner.childImageSharp.fluid,
                  media: `(min-width: 600px)`,
                },
              ]}
            />
          </BannerContainer>
        )}
      />
    )
  }
}
export default MidYearRecharge2024XgtBanner
