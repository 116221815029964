import React from 'react'
import { Media, mbSize } from '@renderbus/common/theme'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Billboard } from '@renderbus/common/components'
import TitleImg from '../../images/banner/animation-extreme-speed-banner/title.png'
import { isTest } from '@renderbus/common/service'

export const PictureBannerContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  color: white;
  padding-top: 176px;
  font-family: 'Source Han Sans CN';
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    justify-content: start;
    padding-top: 37vw;
  }
`

export const BannerWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  img {
    width: 684px;
  }
  ${Media.lessThan(Media.small)} {
    img {
      width: ${mbSize(697)};
    }
  }
`
export const Desc = styled.div`
  font-size: 30px;
  color: #ffffff;
  line-height: 29px;
  /* text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.4); */
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
  letter-spacing: 1px;
  margin: 16px 0 40px;
  p {
    margin: 0;
  }
  span {
    color: #ffecb2;
    font-weight: bold;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(30)};
    line-height: unset;
    letter-spacing: ${mbSize(2)};
    margin: ${mbSize(16)} 0 ${mbSize(40)};
  }
`

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 40px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  background: radial-gradient(circle at 100%, #3fbc2b 0%, #40c570 100%);
  transition: all 0.3s;
  :hover {
    box-shadow: 0px 10px 30px 10px rgba(64, 194, 93, 0.3);
  }
  cursor: pointer;
  ${Media.lessThan(Media.small)} {
    font-weight: bold;
    width: ${mbSize(240)};
    height: ${mbSize(60)};
    line-height: ${mbSize(60)};
    font-size: ${mbSize(24)};
    /* margin-top: ${mbSize(40)}; */
  }
`

export default React.memo(function AnimationExtremeSpeedBanner() {
  const data = useStaticQuery(graphql`
    query {
      banner: file(
        relativePath: { regex: "/banner/animation-extreme-speed-banner/banner_bg.png/" }
      ) {
        ...fluidImage
      }
      bannerMb: file(
        relativePath: { regex: "/banner/animation-extreme-speed-banner/banner_bg_mobile.png/" }
      ) {
        ...fluidImage
      }
    }
  `)

  const handleClick = e => {
    e.nativeEvent.stopImmediatePropagation()
    if (typeof window !== 'undefined') {
      const baseLink = isTest() ? 'http://pre.renderbus.com:8081' : 'https://www.renderbus.com'
      window.location.href = `${baseLink}/ysdh-xrj-lqzn.html`
    }
  }

  return (
    <PictureBannerContainer>
      <BannerWrap>
        <img src={TitleImg} alt='' />
        <Desc>
          注册即送 <span>100元无门槛渲染券+7天五折渲染权益</span>
        </Desc>
        <Button onClick={handleClick}>了解详情</Button>
      </BannerWrap>
      <Billboard
        fluid={[
          data.banner.childImageSharp.fluid,
          { ...data.bannerMb.childImageSharp.fluid, media: `(max-width: 780px)` },
        ]}
      />
    </PictureBannerContainer>
  )
})
