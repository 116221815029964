import { Billboard } from '@renderbus/common/components'
import { color } from '@renderbus/common/theme'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { useMedia } from 'use-media'
import { BannerContainer } from './atom'
import Film from '../../images/banner/summer-2023-archive-example/film.webp'
import Line from '../../images/banner/summer-2023-archive-example/line.webp'
import LineMb from '../../images/banner/summer-2023-archive-example/line-mb.webp'

const Title = styled.p`
  font-size: 60px;
  line-height: 72px;
  font-weight: bold;
  margin: 0;
  color: #ffffff;
  background: linear-gradient(0deg, #6b6b6b 0%, #ffffff 65.5517578125%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (max-width: 768px) {
    font-size: 30px;
    line-height: 35px;
  }
`

const SubTitle = styled.p`
  font-size: 16px;
  line-height: 30px;
  color: ${color.white};
  margin: 19px 0 0;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 81px;
  img {
    position: absolute;
  }
  @media screen and (max-width: 768px) {
    font-size: 0.857rem;
    line-height: 1.29rem;
    margin: 14px 0 0;
  }
`

const ContentWrapper = styled.div`
  position: absolute;
  top: 53%;
  left: 0;
  width: 100%;
  text-align: center;
  @media screen and (max-width: 768px) {
    top: 47%;
  }
`

const TopTitle = styled.div`
  width: 550px;
  height: 60px;
  background-image: url(${Film});
  background-size: auto;
  margin: auto;
  color: ${color.white};
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  font-family: Source Han Sans CN;
  @media screen and (max-width: 768px) {
    width: 344px;
    height: 38px;
    background-size: 100%;
    font-size: 15px;
    letter-spacing: 4px;
    padding-left: 5px;
  }
`

const Summer2023ArchiveExampleBanner = () => {
  const isMobile = useMedia({ maxWidth: 768 })
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "banner/summer-2023-archive-example/bg.webp" }) {
        ...fluidImage
      }
      bannerMb: file(relativePath: { eq: "banner/summer-2023-archive-example/bg-mb.webp" }) {
        ...fluidImage
      }
    }
  `)
  return (
    <BannerContainer>
      <ContentWrapper>
        <TopTitle>瑞云渲染助力2023年暑期档</TopTitle>
        <Title>热映电影及番剧</Title>
        <SubTitle>
          {isMobile ? (
            <>
              <img src={LineMb} alt='' />
              《超级英雄美猴王：齐天儿》《超级飞侠：乐迪加速》
              <br />
              《茶啊二中》《封神第一部》《大主宰》
              <br />
              均由 Renderbus 瑞云渲染提供云渲染服务
            </>
          ) : (
            <>
              <img src={Line} alt='' srcset='' />
              《超级英雄美猴王：齐天儿》《超级飞侠：乐迪加速》《茶啊二中》《封神第一部》《大主宰》
              <br />
              均由 Renderbus 瑞云渲染提供云渲染服务
            </>
          )}
        </SubTitle>
      </ContentWrapper>
      <Billboard
        fluid={[
          data.banner.childImageSharp.fluid,
          {
            ...data.bannerMb.childImageSharp.fluid,
            media: '(max-width: 768px)',
          },
        ]}
        imgStyle={{ objectFit: 'fill' }}
        alt=''
      />
    </BannerContainer>
  )
}

export default Summer2023ArchiveExampleBanner
