import styled from 'styled-components'
import { Media, spacing, typography } from '../../../../../common/theme'

export const ActivityBannerContainer = styled.div`
  position: relative;
  height: 600px;
  ${Media.lessThan(Media.small)} {
    height: 100vh;
  }
`

export const IndexBannerContainer = styled.div`
  padding-top: 10%;
  position: relative;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ${Media.lessThan(Media.small)} {
    padding: 100px 25px 0;
  }
`

export const ActivityBannerContent = styled.div`
  max-width: 1420px;
  margin: auto;
  padding: 120px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ${Media.lessThan(Media.small)} {
    padding: 100px 25px 0;
    align-items: center;
  }
`

export const Badge = styled.img`
  position: absolute;
  top: 80px;
  left: 0;
  ${Media.lessThan(Media.small)} {
    height: 30px;
    top: 60px;
  }
`

export const ActivityBadge = styled(Badge)`
  top: 70px;
`

export const RechargeSubTitle = styled.p`
  width: 500px;
  height: 60px;
  margin: 0 0 ${spacing.base};
  background: #198e88;
  border: 2px solid #00fed9;
  border-radius: 30px;
  line-height: 60px;
  color: #fedc91;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    width: 250px;
    height: 30px;
    font-size: ${typography.text};
    line-height: 28px;
  }
`

export const BannerTitle = styled.img`
  max-width: 100%;
`

export const BannerBtn = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
  background: linear-gradient(0deg, #fc5518, #f5805d);
  border: none;
  border-radius: 25px;
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
  ${Media.lessThan(Media.small)} {
    margin-top: ${spacing.small};
    width: 140px;
    height: 35px;
    font-size: ${typography.h4};
  }
`

export const InexBannerBtn = styled(BannerBtn)`
  transform: translateY(-50%);
`

export const BannerInfo = styled.p`
  margin: 0;
  font-size: 20px;
  color: #005949;
  font-weight: 400;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.text};
  }
`
