import React from 'react'
import { Flex } from '@renderbus/common/components'
import { Cycle, Tooltips, Container, MoblieContainer, Sparkles, SparklesItem } from './atoms'

const sparkles = [
  {
    left: 200,
    top: 20,
    content: '奥斯卡级专业云渲染团队',
  },
  {
    left: 950,
    top: 40,
    content: '针对项目提供私人定制服务',
  },
  {
    left: 320,
    top: 120,
    content: '7X24小时在线技术支持',
  },
  {
    left: 750,
    top: 150,
    content: '专属TD为您的项目保驾护航',
  },
]

class Spotlight extends React.PureComponent {
  state = { index: 1 }
  timerID = -1
  componentDidMount() {
    this.timerID = window.setInterval(
      () => this.setState({ index: Math.floor(Math.random() * 4) }),
      1000,
    )
  }
  componentWillUnmount() {
    window.clearInterval(this.timerID)
  }
  render() {
    const { index } = this.state
    return (
      <React.Fragment>
        <Container>
          {sparkles.map((t, i) => (
            <Flex key={i} style={{ position: 'absolute', top: t.top, left: t.left }}>
              <Cycle active={i === index} />
              <Tooltips>{t.content}</Tooltips>
            </Flex>
          ))}
        </Container>
        <MoblieContainer>
          {sparkles.map((t, i) => (
            <Sparkles xs='6' key={i}>
              <SparklesItem>
                <Cycle active={true} />
                {t.content}
              </SparklesItem>
            </Sparkles>
          ))}
        </MoblieContainer>
      </React.Fragment>
    )
  }
}

export default Spotlight
