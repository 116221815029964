import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { API } from '@renderbus/common/utils'
import axios from 'axios'
import { Media, mbSize } from '@renderbus/common/theme'
import { graphql, useStaticQuery } from 'gatsby'
import { BannerContainer } from './atom'
import { Billboard } from '@renderbus/common/components'
import arrowImg from '../../images/banner/picture-client/picture-client-arrow.png'

const PictureClientWrapper = styled.div``

const PictureClientTop = styled.div`
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const PictureClientButton = styled.a`
  width: 200px;
  height: 40px;
  background: linear-gradient(-90deg, #3bc1ed 0%, #2676ff 100%);
  border-radius: 20px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  line-height: 40px;
  margin-top: 20px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(240)};
    height: ${mbSize(48)};
    background: linear-gradient(-90deg, #3bc1ed 0%, #2676ff 100%);
    border-radius: ${mbSize(48)};
    font-size: ${mbSize(24)};
    color: #ffffff;
    line-height: ${mbSize(48)};
  }
`
const PictureClientTopTitle = styled.div`
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  position: relative;
  ::before {
    content: '';
    position: absolute;
    width: 60px;
    height: 2px;
    top: 21px;
    left: -70px;
    top: 21px;
    background: linear-gradient(90deg, rgba(38, 118, 255, 0) 0%, #2676ff 60%);
  }
  ::after {
    content: '';
    position: absolute;
    width: 60px;
    height: 2px;
    right: -50px;
    top: 21px;
    background: linear-gradient(-90deg, rgba(38, 118, 255, 0) 0%, #2676ff 60%);
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(30)};
    ::before {
      width: ${mbSize(60)};
      height: ${mbSize(2)};
      top: ${mbSize(21)};
      left: ${mbSize(-70)};
      top: ${mbSize(21)};
    }
    ::after {
      width: ${mbSize(60)};
      height: ${mbSize(2)};
      right: ${mbSize(-50)};
      top: ${mbSize(21)};
    }
  }
`

const PictureClientTitle = styled.div`
  font-size: 48px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  .picture-arrow-img {
    position: absolute;
    right: -60px;
    width: 128px;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(48)};
    .picture-arrow-img {
      right: ${mbSize(-60)};
      width: ${mbSize(128)};
    }
  }
`

const Emphasis = styled.em`
  color: #2676ff;
  font-style: normal;
`

export default React.memo(function PictureClientBanner({ isMain = true, className = '' }) {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { regex: "/banner/picture-client/picture-client-bg.png/" }) {
        ...fluidImage
      }
      bannerMb: file(relativePath: { regex: "/banner/picture-client/picture-client-bg-mb.png/" }) {
        ...fluidImage
      }
    }
  `)
  const [downloadUrl, setDownloadUrl] = useState('javascript:;')
  useEffect(() => {
    async function fetchData() {
      const instance = axios.create({
        baseURL: API.auth.downloadHost,
      })
      const {
        data: { download_url },
      } = await instance.get(API.auth.vfxNewVersion)
      setDownloadUrl(download_url)
    }
    fetchData()
  }, [])

  return (
    <BannerContainer>
      <PictureClientWrapper>
        <PictureClientTop>
          <PictureClientTopTitle>全新升级！</PictureClientTopTitle>
          <PictureClientTitle>
            效果图客户端<Emphasis>新版上线</Emphasis>
            <img className='picture-arrow-img' src={arrowImg} alt='arrow' />
          </PictureClientTitle>
          <PictureClientButton href={downloadUrl} download>
            新版客户端下载
          </PictureClientButton>
        </PictureClientTop>
        {/* <PictureClientBottom>
          <img src={clientMainImg} />
        </PictureClientBottom> */}
      </PictureClientWrapper>
      <Billboard
        fluid={[
          data.bannerMb.childImageSharp.fluid,
          {
            ...data.banner.childImageSharp.fluid,
            media: `(min-width: 780px)`,
          },
        ]}
        alt='新版效果图客户端下载'
      />
    </BannerContainer>
  )
})
