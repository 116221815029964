import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Billboard } from '@renderbus/common/components'
import { Media } from '@renderbus/common/theme'
import { graphql, StaticQuery } from 'gatsby'
import BadgeImg from '../../images/banner/badge.png'

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  text-align: center;
`

const BadgeContainer = styled.div`
  width: 1400px;
  margin: auto;
  position: relative;
`

const Badge = styled.img`
  position: absolute;
  top: 60px;
  left: 0;
  ${Media.lessThan(Media.small)} {
    left: 50px;
    width: 74px;
    height: 60px;
  }
`

class TerracottaBanner extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(relativePath: { regex: "/banner/terracotta/terracotta-bg.png/" }) {
              ...fluidImage
            }
            bannerMb: file(
              relativePath: { regex: "/banner/terracotta/terracotta-bg-mobile.png/" }
            ) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb }) => (
          <BannerContainer>
            <BadgeContainer>
              <Badge src={BadgeImg} alt='瑞云渲染案例' />
            </BadgeContainer>
            <Billboard
              fluid={[
                bannerMb.childImageSharp.fluid,
                {
                  ...banner.childImageSharp.fluid,
                  media: `(min-width: 780px)`,
                },
              ]}
              alt='俑之城'
            />
          </BannerContainer>
        )}
      />
    )
  }
}
export default TerracottaBanner
