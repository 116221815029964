import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Billboard, Button } from '@renderbus/common/components'
import { Media, hideSMUp, hideSMDown } from '@renderbus/common/theme'
import { graphql, Link, StaticQuery } from 'gatsby'
import ContentImage from '../../images/picture-price-gift/content.png'
import ContentMobileImage from '../../images/picture-price-gift/content-mobile.png'
import ButtonBg from '../../images/picture-price-gift/button-bg.png'
import ButtonBgMb from '../../images/picture-price-gift/button-bg-mb.png'
import ButtonBgHover from '../../images/picture-price-gift/button-bg-hover.png'
const ContentImg = styled.img`
  ${hideSMDown};
  top: 0;
  width: 686px;
`
const ContentMobileImg = styled.img`
  ${hideSMUp};
  width: 314px;
`
const DetailButton = styled(Button)`
  width: 211px;
  height: 66px;
  background-image: url(${ButtonBg});
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  text-align: center;
  line-height: 50px;
  font-size: 22px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
  .button-text {
    ${hideSMDown};
  }
  .button-text-mobile {
    ${hideSMUp};
  }
  &:hover {
    background-image: url(${ButtonBgHover});
  }
  ${Media.lessThan(Media.small)} {
    width: 112px;
    height: 25px;
    background-image: url(${ButtonBgMb});
    font-size: 12px;
    color: #61310b;
    line-height: 25px;
  }
`
const ContentWrapper = styled.div`
  margin: 42px 0 65px;
  ${Media.lessThan(Media.small)} {
    margin: 10px 0 35px;
  }
`

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  text-align: left;
  ${Media.lessThan(Media.small)} {
    height: 650px;
  }
`
const Title = styled.div`
  width: 170px;
  height: 36px;
  background: #8ae7c0;
  border-radius: 18px;
  font-weight: 400;
  color: #0b1e16;
  line-height: 36px;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    width: 97px;
    height: 20px;
    border-radius: 10px;
    font-size: 10px;
    font-weight: 500;
    color: #062116;
    line-height: 20px;
  }
`
const BannerContent = styled.div`
  max-width: 1400px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 110px;
  @media screen and (max-width: 1500px) {
    padding-bottom: 40px;
    max-width: 1200px;
  }
  @media screen and (min-width: 2000px) {
    padding-bottom: 270px;
  }

  ${Media.lessThan(Media.small)} {
    align-items: center;
    justify-content: start;
    padding-top: 80px;
    padding-bottom: 0;
  }
`

class PicturePriceGiftBanner extends PureComponent {
  render() {
    this.isZoomOut = window ? window.devicePixelRatio >= 1.2 : false
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(relativePath: { regex: "/picture-price-gift/bg.png/" }) {
              ...fluidImage
            }
            bannerMb: file(relativePath: { regex: "/picture-price-gift/bg_mobile.png/" }) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb }) => (
          <BannerContainer>
            <BannerContent>
              <Title>效果图专享</Title>
              <ContentWrapper>
                <ContentImg src={ContentImage} alt='' />
                <ContentMobileImg src={ContentMobileImage} alt='' />
              </ContentWrapper>
              <DetailButton
                id='index-xgt'
                as={Link}
                to='/architectural-rendering-monthly-promotion.html'
              >
                <span className='button-text'>立即参与</span>
                <span className='button-text-mobile'>立即参与</span>
              </DetailButton>
            </BannerContent>

            <Billboard
              fluid={[
                bannerMb.childImageSharp.fluid,
                {
                  ...banner.childImageSharp.fluid,
                  media: `(min-width: 750px)`,
                },
              ]}
            />
          </BannerContainer>
        )}
      />
    )
  }
}
export default PicturePriceGiftBanner
