import React from 'react'
import styled from 'styled-components'
import { Billboard } from '@renderbus/common/components'
import { Media, mbSize } from '@renderbus/common/theme'
import { graphql, useStaticQuery } from 'gatsby'
import MaxIcon from '../../images/banner/xgt-upgrade-banner/3dsmax.png'
import CoronaIcon from '../../images/banner/xgt-upgrade-banner/corona.png'
import VrayIcon from '../../images/banner/xgt-upgrade-banner/vray.png'
import { isTest } from '@renderbus/common/service'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  color: white;
  padding-top: 60px;
  * {
    line-height: 1;
  }
`

export const Title = styled.div`
  position: relative;
  font-size: 60px;
  font-weight: bold;
  color: #69c2fc;
  background: linear-gradient(#d4eafa, #307cf6);
  margin-top: 14.35vh;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 120px;
    height: 3px;
  }
  &::before {
    top: 50%;
    left: -20px;
    transform: translate(-100%, -50%);
    background: linear-gradient(90deg, transparent 0%, #69c2fc 100%);
  }
  &::after {
    right: -20px;
    bottom: 50%;
    transform: translate(100%, 50%);
    background: linear-gradient(90deg, #69c2fc 0%, transparent 100%);
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(60)};
    margin-top: 9vh;
    &::before,
    &::after {
      width: ${mbSize(100)};
      height: 2px;
    }
  }
`
const SubTitle = styled.div`
  font-weight: bold;
  font-size: 60px;
  margin: 19px 0 44px 0;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(60)};
    margin: ${mbSize(19)} 0 44px 0;
  }
`

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 201px;
  height: 61px;
  border-radius: 10px 10px 10px 10px;
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  line-height: 36px;
  > img {
    margin-right: 14px;
  }
  &.max {
    background: rgba(57, 165, 204, 0.6);
    width: 421px;
    margin-bottom: 19px;
  }
  &.corona {
    background: rgba(255, 113, 32, 0.6);
  }
  &.vray {
    background: rgba(40, 87, 175, 0.6);
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(301)};
    height: ${mbSize(91)};
    font-size: ${mbSize(28)};
    > img {
      width: ${mbSize(58)};
    }
    &.max {
      width: ${mbSize(631)};
      margin-bottom: ${mbSize(29)};
      font-size: ${mbSize(36)};
    }
  }
`
const IconBoxWrapper = styled.div`
  display: flex;
  margin-bottom: 50px;
  width: 421px;
  justify-content: space-between;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(631)};
  }
`

const Button = styled.a`
  cursor: pointer;
  height: 36px;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background: linear-gradient(90deg, #3d84f1 0%, #2857af 100%);
  border-radius: 24px;
  &:hover {
    background: linear-gradient(90deg, #2857af 0%, #2857af 100%);
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(240)};
    height: ${mbSize(60)};
    font-size: ${mbSize(24)};
  }
`

export default React.memo(function XgtUpgradeBanner() {
  const link = `https://${isTest() ? 'task-pre' : 'task'}.renderbus.com/?platformType=2`
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { regex: "/banner/xgt-upgrade-banner/banner.png/" }) {
        ...fluidImage
      }
      bannerMb: file(relativePath: { regex: "/banner/xgt-upgrade-banner/banner_mobile.png/" }) {
        ...fluidImage
      }
    }
  `)
  return (
    <Container>
      <Title>服务升级</Title>
      <SubTitle>瑞云效果图渲染最新支持</SubTitle>
      <IconBox className='max'>
        <img src={MaxIcon} alt='max 3d' />
        3ds Max 2024
      </IconBox>
      <IconBoxWrapper>
        <IconBox className='corona'>
          <img src={CoronaIcon} alt='corona' />
          Corona 10
        </IconBox>
        <IconBox className='vray'>
          <img src={VrayIcon} alt='vray' />
          V-Ray 6.1.2
        </IconBox>
      </IconBoxWrapper>
      <Button href={link}>立即体验</Button>
      <Billboard
        fluid={[
          data.banner.childImageSharp.fluid,
          { ...data.bannerMb.childImageSharp.fluid, media: `(max-width: 780px)` },
        ]}
      />
    </Container>
  )
})
