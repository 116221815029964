import React from 'react'

const SvgHome01 = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    id='\u56FE\u5C42_1'
    x={0}
    y={0}
    style={{
      enableBackground: 'new 0 0 75 75',
    }}
    viewBox='0 0 75 75'
    {...props}
  >
    <style>
      {
        '.st3,.st5{fill:none;stroke:#13cb75}.st3{stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.st5{stroke-width:2;stroke-miterlimit:2}'
      }
    </style>
    <text
      style={{
        fontSize: 14,
        fontFamily: '&quot',
        fill: '#13cb75',
      }}
      transform='translate(27.874 37.5)'
    >
      {'FREE'}
    </text>
    <path
      d='M70 21.51c0-2.76-2.24-5-5-5H10c-2.76 0-5 2.24-5 5v38c0 2.76 2.24 5 5 5h55c2.76 0 5-2.24 5-5v-38z'
      className='st3'
    />
    <path d='m37.14 51.44 14.01-6.7c1.65-.79 3.6-.61 5.08.46L70 55.13' className='st3' />
    <path
      d='m5 60.32 19.74-13.15a4.997 4.997 0 0 1 5.42-.08l18.94 11.8M10.32 16.51l47.61-6.95a4.996 4.996 0 0 1 5.43 3.27l1.32 3.68'
      className='st3'
    />
    <path
      d='m24.84 28.36-6.5-3.3-6.5 3.3V36l6.5 3.82 6.5-3.82v-7.64z'
      style={{
        fill: 'none',
        stroke: '#13cb75',
        strokeWidth: 2,
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
    />
    <path d='m11.84 28.36 6.5 3.48 6.5-3.48M18.34 31.83v8' className='st5' />
  </svg>
)

export default SvgHome01
