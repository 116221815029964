import React from 'react'
import styled from 'styled-components'
import { Billboard } from '@renderbus/common/components'
import { Media, mbSize, pcSize } from '@renderbus/common/theme'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { UserAuthorizationService, baseLink } from '@renderbus/common/service'

const TextImg = styled(Img)`
  width: 516px;
  height: 197px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(522)};
    height: ${mbSize(175)};
  }
`

const MarkImg = styled(Img)`
  width: 667px;
  height: 110px;
  margin: 36px 0 25px 0;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(667)};
    height: ${mbSize(110)};
    margin: ${mbSize(27)} 0 ${mbSize(30)} 0;
  }
`

const ButtonImg = styled(Img)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 277px;
  height: 115px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(215)};
    height: ${mbSize(51)};
  }
`

const ButtonWrapper = styled.a`
  position: relative;
  cursor: pointer;
  width: 277px;
  height: 115px;
  margin-left: -30px;
  .hover {
    visibility: hidden;
  }
  &:hover {
    visibility: hidden;
    .hover {
      visibility: visible;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(215)};
    height: ${mbSize(51)};
    margin-left: 0;
  }
`

const Container = styled.div`
  position: relative;
  height: 100vh;
  padding-top: 60px;
`

const ContentWrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 19.63vh 0 0 ${pcSize(258)};
  ${Media.lessThan(Media.small)} {
    margin: 0 0 0 0;
    padding-top: ${mbSize(88)};
    align-items: center;
  }
`

export default React.memo(function AIBanner() {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { regex: "/banner/ai-banner/bg.png/" }) {
        ...fluidImage
      }
      bannerMb: file(relativePath: { regex: "/banner/ai-banner/bg_mobile.png/" }) {
        ...fluidImage
      }
      textImg: file(relativePath: { regex: "/banner/ai-banner/text.png/" }) {
        ...fluidImage
      }
      textMbImg: file(relativePath: { regex: "/banner/ai-banner/text_mobile.png/" }) {
        ...fluidImage
      }
      markImg: file(relativePath: { regex: "/banner/ai-banner/mark.png/" }) {
        ...fluidImage
      }
      btnImg: file(relativePath: { regex: "/banner/ai-banner/btn-normal.png/" }) {
        ...fluidImage
      }
      btnHoverImg: file(relativePath: { regex: "/banner/ai-banner/btn-hover.png/" }) {
        ...fluidImage
      }
    }
  `)

  const onClick = () => {
    if (!!UserAuthorizationService.isLogined()) {
      window.location.href = `${baseLink()}?platformType=2`
    } else {
      window.location.href = `${baseLink()}/sso/register?businessType=0`
    }
  }

  return (
    <Container onClick={onClick}>
      <ContentWrapper>
        <TextImg
          fluid={[
            data.textImg.childImageSharp.fluid,
            { ...data.textMbImg.childImageSharp.fluid, media: `(max-width: 780px)` },
          ]}
        />
        <MarkImg fluid={data.markImg.childImageSharp.fluid} />
        <ButtonWrapper onClick={onClick}>
          <ButtonImg fluid={data.btnImg.childImageSharp.fluid} />
          <ButtonImg className='hover' fluid={data.btnHoverImg.childImageSharp.fluid} />
        </ButtonWrapper>
      </ContentWrapper>
      <Billboard
        fluid={[
          data.banner.childImageSharp.fluid,
          { ...data.bannerMb.childImageSharp.fluid, media: `(max-width: 780px)` },
        ]}
      />
    </Container>
  )
})
