import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BadgeImg from '../../../images/xgt-101-recharge/picture-user-tip.png'
import BannerTitleImg from '../../../images/xgt-101-recharge/banner-title.png'
import {
  ActivityBadge,
  ActivityBannerContainer,
  ActivityBannerContent,
  BannerBtn,
  BannerInfo,
  BannerTitle,
  RechargeSubTitle,
} from './atom'
import { Billboard } from '../../../../../common/components'

const ActivityBanner = React.memo(function() {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "xgt-101-recharge/activity-banner-bg.png" }) {
        ...fluidImage
      }
      bannerMobile: file(relativePath: { eq: "xgt-101-recharge/banner-mobile-bg.png" }) {
        ...fluidImage
      }
    }
  `)
  return (
    <ActivityBannerContainer>
      <ActivityBannerContent>
        <RechargeSubTitle>充值返100%，实名再送会员</RechargeSubTitle>
        <BannerTitle src={BannerTitleImg} alt='国庆放价 一渲到底' />
        <BannerBtn
          id='xgt101-ban'
          as='a'
          href='https://account.renderbus.com/center/user/topUp?activeTab=3'
          target='_blank'
        >
          马上参加 >
        </BannerBtn>
        <BannerInfo style={{ marginTop: '20px' }}>活动时间：2021.10.12-11.14</BannerInfo>
      </ActivityBannerContent>
      <ActivityBadge src={BadgeImg} alt='瑞云渲染案例' />
      <Billboard
        fluid={[
          data.bannerMobile.childImageSharp.fluid,
          {
            ...data.banner.childImageSharp.fluid,
            media: `(min-width: 780px)`,
          },
        ]}
      />
    </ActivityBannerContainer>
  )
})

export default ActivityBanner
