import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import BadgeImg from '../../../images/xgt-101-recharge/picture-user-tip.png'
import BannerTitleImg from '../../../images/xgt-101-recharge/banner-title.png'
import {
  Badge,
  InexBannerBtn,
  BannerTitle,
  IndexBannerContainer,
  RechargeSubTitle,
  BannerInfo,
} from './atom'
import { Billboard } from '../../../../../common/components'

const IndexBanner = React.memo(function() {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "xgt-101-recharge/banner-bg.png" }) {
        ...fluidImage
      }
      bannerMobile: file(relativePath: { eq: "xgt-101-recharge/banner-mobile-bg.png" }) {
        ...fluidImage
      }
    }
  `)
  return (
    <IndexBannerContainer>
      <RechargeSubTitle>充值返100%，实名再送会员</RechargeSubTitle>
      <BannerTitle src={BannerTitleImg} alt='国庆放价 一渲到底' />
      <InexBannerBtn as={Link} to='/xgt-101-recharge.html' id='ban-xgt101'>
        点击查看
      </InexBannerBtn>
      <BannerInfo>活动时间：2021.10.12-11.14</BannerInfo>
      <Badge src={BadgeImg} alt='瑞云渲染案例' />
      <Billboard
        fluid={[
          data.bannerMobile.childImageSharp.fluid,
          {
            ...data.banner.childImageSharp.fluid,
            media: `(min-width: 780px)`,
          },
        ]}
      />
    </IndexBannerContainer>
  )
})

export default IndexBanner
