import styled from 'styled-components'
import { color, shadows, hideSMDown, hideSMUp, Media, typography } from '@renderbus/common/theme'

export const EvaluateCard = styled.div`
  position: relative;
  height: 320px;
  margin-top: 75px;
  margin-bottom: 40px;
  color: white;
  background-color: ${color.panel};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  padding: 0 2.5em;
  box-shadow: ${shadows[3]};
  padding-top: ${({ hasIcon }) => hasIcon && '60px'};
  .img-box {
    width: 140px;
    height: 140px;
    top: -75px;
    position: absolute !important;
  }
  ${Media.lessThan(Media.small)} {
    height: 240px;
    font-size: ${typography.textSmall};
    .img-box {
      transform: scale(0.8);
    }
  }
  p.more {
    color: ${color.primary};
  }
`
export const More = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background-color: rgba(5, 189, 110, 1);
  top: -75px;
  position: absolute;
  ::after,
  ::before {
    content: '';
    position: absolute;
    color: rgba(255, 255, 255, 1);
    border-radius: 8px;
    transform: translate(-50%, -50%);
    top: 50%;
  }
  ::after {
    width: 50px;
    border-top: 8px solid;
  }
  ::before {
    height: 50px;
    border-right: 8px solid;
  }
  ${Media.lessThan(Media.small)} {
    width: 112px;
    height: 112px;
    ::after {
      width: 40px;
      border-top: 6px solid;
    }
    ::before {
      height: 40px;
      border-right: 6px solid;
    }
  }
`
export const DesktopOnlySwiper = styled.div`
  ${hideSMDown};
`
export const MobileOnkySwiper = styled.div`
  ${hideSMUp};
`
export const Title = styled.span`
  line-height: 2;
  color: ${color.prompt};
`
