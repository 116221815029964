import React from 'react'
import PorpTypes from 'prop-types'
import styled from 'styled-components'

import { Button } from '@renderbus/common/components'
import { color, Media } from '@renderbus/common/theme'
import { placeholder } from '@renderbus/common/theme/mixin'
import { baseLink } from '@renderbus/common/service'

const Input = styled.input`
  ${placeholder(color.prompt)}
  position: relative;
  display: inline-block;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  line-height: 40px;
  border-radius: 20px 0 0 20px;
  border: 0 solid transparent;
  color: ${color.text};
  background-color: rgba(51, 51, 51, 0.6);
  :-webkit-autofill {
    caret-color: white;
    -webkit-text-fill-color: white;
  }
  :not([disabled]):focus {
    outline: 0;
  }
`
const Wrap = styled.div`
  display: flex;
  width: ${p => (p.width ? p.width : 'auto')};
  max-width: 100%;
  margin: auto;
  > button {
    height: 40px;
    line-height: 40px;
    flex-shrink: 0;
    border-radius: 0 20px 20px 0;
  }
  ${Media.lessThan(Media.small)} {
    width: auto;
  }
`
class PhoneCombo extends React.PureComponent {
  state = { value: '' }
  handleChange = event => {
    this.setState({ value: event.target.value })
  }
  //@see https://stackoverflow.com/questions/44142273/react-ul-with-onblur-event-is-preventing-onclick-from-firing-on-li
  handleClick = e => {
    e.nativeEvent.stopImmediatePropagation()
    if (typeof window !== 'undefined') {
      window.open(
        `${baseLink()}/sso/register?=Bregister&phone=${encodeURIComponent(this.state.value)}`,
        '_blank',
      )
    }
  }
  render() {
    const { width, style, registerText = '领动画40元渲染券' } = this.props
    return (
      <Wrap width={width} style={style}>
        <Input value={this.state.value} onChange={this.handleChange} placeholder='请输入手机号' />
        <Button id='Bregister' onMouseDown={this.handleClick}>
          {registerText}
        </Button>
      </Wrap>
    )
  }
}

PhoneCombo.PorpTypes = {
  width: PorpTypes.string,
}

export default PhoneCombo
