import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { reshape } from '@renderbus/common/utils'
import { Swiper, Row, Column, Wrapper } from '@renderbus/common/components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { EvaluateCard, DesktopOnlySwiper, MobileOnkySwiper, Title, More } from './atoms'

class UserEvaluate extends React.PureComponent {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            userEvaluations: allContentfulUserEvaluation(
              filter: { showInHome: { eq: true } }
              limit: 8
              sort: { fields: rank, order: DESC }
            ) {
              nodes {
                author
                description
                production
                avatar {
                  title
                  fluid {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
            }
          }
        `}
        render={({ userEvaluations }) => {
          const evaluateList = userEvaluations.nodes
          if (!evaluateList[evaluateList.length - 1].isLast) evaluateList.push({ isLast: true })
          return (
            <React.Fragment>
              <DesktopOnlySwiper>
                <Swiper>
                  {reshape(evaluateList, 3).map((clamp, index) => (
                    <Wrapper key={index} style={{ padding: '16px 0' }}>
                      <Row spacing={2}>
                        {clamp.map((c, i) => (
                          <Column xs='4' key={i}>
                            {c.isLast ? (
                              <EvaluateCard
                                as={Link}
                                to='/user-evaluation.html'
                                target='_blank'
                                rel='nofollow'
                              >
                                <More />
                                <p className='more'>查看更多评价</p>
                              </EvaluateCard>
                            ) : (
                              <EvaluateCard hasIcon={!!c.avatar}>
                                {c.avatar && (
                                  <Img
                                    fluid={c.avatar.fluid}
                                    alt={c.avatar.title}
                                    className='img-box'
                                  />
                                )}
                                <p>{c.description}</p>
                                <p>
                                  <span>{c.author}</span>
                                  <br />
                                  <Title>{c.production}</Title>
                                </p>
                              </EvaluateCard>
                            )}
                          </Column>
                        ))}
                      </Row>
                    </Wrapper>
                  ))}
                </Swiper>
              </DesktopOnlySwiper>
              <MobileOnkySwiper>
                <Swiper>
                  {evaluateList.map((c, i) => (
                    <Wrapper key={i}>
                      {c.isLast ? (
                        <EvaluateCard
                          as={Link}
                          to='/user-evaluation.html'
                          target='_blank'
                          rel='nofollow'
                        >
                          <More />
                          <p>查看更多评价</p>
                        </EvaluateCard>
                      ) : (
                        <EvaluateCard hasIcon={!!c.avatar}>
                          {c.avatar && (
                            <Img fluid={c.avatar.fluid} alt={c.avatar.title} className='img-box' />
                          )}
                          <p>{c.description}</p>
                          <p>
                            <span>{c.author}</span>
                            <br />
                            <Title>{c.production}</Title>
                          </p>
                        </EvaluateCard>
                      )}
                    </Wrapper>
                  ))}
                </Swiper>
              </MobileOnkySwiper>
            </React.Fragment>
          )
        }}
      />
    )
  }
}

export default UserEvaluate
