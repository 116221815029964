import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Billboard } from '@renderbus/common/components'
import { Media } from '@renderbus/common/theme'
import { graphql, StaticQuery } from 'gatsby'
import BadgeImg from '../../images/banner/rayvision-side-case.png'

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  text-align: center;
`

const BadgeContainer = styled.div`
  width: 1400px;
  margin: auto;
`

const Badge = styled.img`
  position: absolute;
  top: 100px;
  left: 0;
  ${Media.lessThan(Media.small)} {
    height: 30px;
  }
`

class MyGloryBanner extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(relativePath: { regex: "/banner/my-glory/my-glory.png/" }) {
              ...fluidImage
            }
            bannerMb: file(relativePath: { regex: "/banner/my-glory/my-glory-mobile.png/" }) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb }) => (
          <BannerContainer>
            <BadgeContainer>
              <Badge src={BadgeImg} alt='瑞云渲染案例' />
            </BadgeContainer>
            <Billboard
              fluid={[
                bannerMb.childImageSharp.fluid,
                {
                  ...banner.childImageSharp.fluid,
                  media: `(min-width: 780px)`,
                },
              ]}
              alt='你是我的荣耀'
            />
          </BannerContainer>
        )}
      />
    )
  }
}
export default MyGloryBanner
