import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Billboard, Button } from '@renderbus/common/components'
import { color, Media, spacing, typography } from '@renderbus/common/theme'
import { graphql, StaticQuery } from 'gatsby'
import AuthNoticeIconImg from '../../images/banner/auth-notice-banner/auth-notice-icon.png'
import { API, Axios } from '@renderbus/common/utils'

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  padding-top: 156px;
  @media (max-width: 1500px) {
    padding-top: 80px;
  }
`

const BannerInfoContainer = styled.div`
  margin: 0 auto;
  max-width: 600px;
  padding: 230px 36px 0;
  position: relative;
  z-index: 1;
  h1 {
    margin: 0 0 ${spacing.small};
    height: 60px;
    line-height: 60px;
    font-size: ${typography.title};
    font-weight: bold;
    color: white;
    text-align: center;
    background: #38c66866;
  }
  p {
    margin: 0;
    font-size: ${typography.h4};
    line-height: ${typography.h2};
    color: white;
  }
  img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    z-index: -1;
  }
  ${Media.lessThan(Media.small)} {
    padding: 140px 18px 0;
    h1 {
      margin: 0 0 ${spacing.small};
      font-size: ${typography.h3};
      height: ${typography.title};
      line-height: ${typography.title};
    }
    p {
      padding: 0 5px;
      font-size: ${typography.textThin};
      line-height: ${typography.h4};
    }
  }
`

export const ButtonGroupContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: ${spacing.base} 0 0;
  a,
  button {
    width: 180px;
    height: 40px;
    line-height: 40px;
    font-size: ${typography.h4};
    border-radius: 20px;
  }
  ${Media.lessThan(Media.small)} {
    a,
    button {
      width: 135px;
      height: ${spacing.large};
      line-height: ${spacing.large};
      font-size: ${typography.textSmall};
    }
  }
`

const instance = new Axios(API.auth.host)

class AuthNoticeBanner extends PureComponent {
  redirectToAuth() {
    instance
      .post(API.auth.getAuthToken)
      .then(res => {
        if (res.code === 200) {
          window.open(`https://account.renderbus.com/authenticate?token=${res.data}`)
        } else {
          throw res
        }
      })
      .catch(e => {
        console.error(e)
        window.location.href = 'https://task.renderbus.com/sso'
      })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(
              relativePath: { regex: "/banner/auth-notice-banner/auth-notice-banner.png/" }
            ) {
              ...fluidImage
            }
          }
        `}
        render={({ banner }) => (
          <BannerContainer>
            <BannerInfoContainer>
              <h1>实名认证通知</h1>
              <p>
                根据国家关于实名认证的相关要求，我司已开展用户实名认证审查，如未完成认证，您的账户后续将无法享受更多权益，为了不影响您的账户使用和保护您的账户安全，请您尽快完成认证，感谢您的理解与支持！
              </p>
              <ButtonGroupContainer>
                <Button
                  color='primary'
                  backgroundColor='white'
                  id='ban-smljgd'
                  as='a'
                  href='https://www.renderbus.com/news/post-id-913/'
                >
                  了解更多
                </Button>
                <Button backgroundColor={color.primary} id='ban-smrz' onClick={this.redirectToAuth}>
                  前去实名
                </Button>
              </ButtonGroupContainer>
              <img src={AuthNoticeIconImg} alt='notice' />
            </BannerInfoContainer>
            <Billboard fluid={banner.childImageSharp.fluid} />
          </BannerContainer>
        )}
      />
    )
  }
}
export default AuthNoticeBanner
