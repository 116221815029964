import { Billboard } from '@renderbus/common/components'
import { mbSize, Media } from '@renderbus/common/theme'
import { graphql, StaticQuery } from 'gatsby'
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import rightPng from '../../images/banner/dingding/right.png'

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    height: 100vh;
  }
`

const RaytoolsBannerContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const BannerBox = styled.div`
  margin-top: 150px;
  width: 1470px;
  height: 553px;
  display: flex;
  .left {
    flex: 1;
    height: 100%;
    padding-left: 100px;
    padding-right: 130px;
  }
  .right {
    flex-basis: 649px;
    flex-shrink: 0;
    flex-grow: 0;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 0px;
    width: 100%;
    flex-direction: column;
    .left {
      padding: 0 ${mbSize(35)};
    }
    .right {
      flex: 1;
      flex-shrink: 0;
      width: 100%;
      height: ${mbSize(547)};
      margin-top: ${mbSize(60)};
      display: flex;
      justify-content: center;
      img {
        width: ${mbSize(640)};
      }
    }
  }
`
const Title = styled.div`
  margin-top: 60px;
  font-size: 56px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  line-height: 70px;
  text-align: left;
  white-space: nowrap;
  ${Media.lessThan(Media.small)} {
    margin-top: ${mbSize(250)};
    padding: 0 ${mbSize(20)};
    font-size: ${mbSize(60)};
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    line-height: ${mbSize(72)};
  }
`
const NewTitle = styled(Title)`
  margin-top: 0px;
  span {
    color: #11cb75;
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 0;
  }
`

const PopCardBox = styled.div`
  margin-top: 39px;
  height: 96px;
  .row {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
  }
  .row:nth-child(2) {
    margin-top: 16px;
  }
  ${Media.lessThan(Media.small)} {
    margin-top: ${mbSize(39)};
    .row {
      height: ${mbSize(60)};
    }
    .row:nth-child(2) {
      margin-top: ${mbSize(16)};
    }
  }
`
const PopCard = styled.div`
  width: 280px;
  height: 40px;
  background: #f5fffa;
  line-height: 40px;
  text-align: center;
  color: #11cb75;
  border-radius: 6px;
  font-size: 18px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(330)};
    height: ${mbSize(48)};
    font-size: ${mbSize(22)};
    line-height: ${mbSize(48)};
  }
`
const LoginButtonBox = styled.div`
  margin-top: 40px;
  ${Media.lessThan(Media.small)} {
    margin-top: ${mbSize(20)};
    display: flex;
    justify-content: center;
  }
`

const LoginButton = styled.div`
  width: 260px;
  height: 48px;
  background: #11cb75;
  border-radius: 24px;
  line-height: 48px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  :hover {
    box-shadow: 0px 4px 7px 1px rgba(64, 194, 133, 0.3);
  }
  ${Media.lessThan(Media.small)} {
    height: ${mbSize(60)};
    font-size: ${mbSize(24)};
    line-height: ${mbSize(60)};
  }
`

class DingdingBanner extends PureComponent {
  goTo() {
    window.location.href = 'https://www.renderbus.com/news/post-id-1241/?index'
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(relativePath: { regex: "/dingding/banner_bg.png/" }) {
              ...fluidImage
            }
            bannerMb: file(relativePath: { regex: "/dingding/banner_mb_bg.png/" }) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb }) => (
          <BannerContainer>
            <RaytoolsBannerContent>
              <BannerBox>
                <div className='left'>
                  <Title>瑞云渲染接入钉钉系统</Title>
                  <NewTitle>
                    让企业<span>渲染管理更高效!</span>
                  </NewTitle>
                  <PopCardBox>
                    <div class='row'>
                      <PopCard>五分钟快速集成企业钉钉服务</PopCard>
                      <PopCard>一键批量导入企业渲染子账号</PopCard>
                    </div>
                    <div class='row'>
                      <PopCard>瑞云渲染&钉钉组织架构同步</PopCard>
                      <PopCard>可视化逐级监控渲染员工作业</PopCard>
                    </div>
                  </PopCardBox>
                  <LoginButtonBox>
                    <LoginButton onClick={this.goTo}>认证企业账号集成钉钉登录</LoginButton>
                  </LoginButtonBox>
                </div>
                <div className='right'>
                  <img src={rightPng} alt='' />
                </div>
              </BannerBox>
            </RaytoolsBannerContent>
            <Billboard
              fluid={[
                bannerMb.childImageSharp.fluid,
                {
                  ...banner.childImageSharp.fluid,
                  media: `(min-width: 780px)`,
                },
              ]}
            />
          </BannerContainer>
        )}
      />
    )
  }
}
export default DingdingBanner
