import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Button } from '@renderbus/common/components'
import { Card, CardAction, CardContent, CardTitle, CardSubTitle } from './atoms'

class RenderCard extends React.PureComponent {
  render() {
    const { thumbnail, title, subTitle, desc, link, alt } = this.props
    return (
      <Card>
        <Img fluid={thumbnail} alt={alt} />
        <CardContent>
          <CardTitle>{title}</CardTitle>
          <CardSubTitle>{subTitle}</CardSubTitle>
        </CardContent>
        <CardAction>
          <p>{desc}</p>
          <Button variant='outlined' as={Link} to={link}>
            渲染服务
          </Button>
        </CardAction>
      </Card>
    )
  }
}

export default RenderCard
