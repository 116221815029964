import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Billboard, Button } from '@renderbus/common/components'
import { color, Media, spacing, typography } from '@renderbus/common/theme'
import { graphql, StaticQuery } from 'gatsby'
import AuthIconImg from '../../images/banner/auth-banner/auth-icon.png'
import { API, Axios } from '@renderbus/common/utils'

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
`
const BannerFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: white;
  max-width: 1400px;
  margin: auto;
  padding: 17% 220px 0 0;
  flex-wrap: wrap;
  ${Media.lessThan(Media.small)} {
    padding: 0 ${spacing.large} 0;
    height: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    img {
      width: 160px;
      margin-top: 45px;
    }
  }
`
const BannerInfoContainer = styled.div`
  width: 576px;
  h1 {
    margin: ${spacing.small} 0 40px;
    font-size: ${typography.title};
    font-weight: 500;
  }
  p {
    margin: 0 0 40px;
    font-size: ${typography.h4};
    line-height: ${typography.h3};
  }
  a,
  button {
    width: 200px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    border-radius: 25px;
  }
  a {
    margin-left: ${spacing.base};
    vertical-align: middle;
  }
  ${Media.lessThan(Media.small)} {
    width: auto;
    h1 {
      margin: 0 0 ${spacing.small};
      font-size: ${typography.h3};
    }
    p {
      margin: 0 0 ${spacing.base};
      font-size: ${typography.textThin};
    }
    a,
    button {
      width: 140px;
      height: 35px;
      line-height: 35px;
      font-size: ${typography.h4};
    }
  }
`

const instance = new Axios(API.auth.host)

class AuthBanner extends PureComponent {
  redirectToAuth() {
    instance
      .post(API.auth.getAuthToken)
      .then(res => {
        if (res.code === 200) {
          window.open(`https://account.renderbus.com/authenticate?token=${res.data}`)
        } else {
          throw res
        }
      })
      .catch(e => {
        console.error(e)
        window.location.href = 'https://task.renderbus.com/sso'
      })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(relativePath: { regex: "/banner/auth-banner/auth-background.png/" }) {
              ...fluidImage
            }
            bannerMb: file(
              relativePath: { regex: "/banner/auth-banner/auth-background-mobile.png/" }
            ) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb }) => (
          <BannerContainer>
            <BannerFlexContainer>
              <BannerInfoContainer>
                <h1>实名认证通知</h1>
                <p>
                  根据国家关于实名认证的相关要求，我司已开展用户实名认证审查，如未完成认证，您的账户后续将无法享受更多权益，为了不影响您的账户使用和保护您的账户安全，请您尽快完成认证，感谢您的理解与支持！
                </p>
                <Button backgroundColor={color.primary} id='ban-smrz' onClick={this.redirectToAuth}>
                  实名认证
                </Button>
                <Button
                  variant='outlined'
                  id='ban-smljgd'
                  as='a'
                  href='https://www.renderbus.com/news/post-id-913/'
                >
                  了解更多
                </Button>
              </BannerInfoContainer>
              <img src={AuthIconImg} alt='实名认证' />
            </BannerFlexContainer>
            <Billboard
              fluid={[
                bannerMb.childImageSharp.fluid,
                {
                  ...banner.childImageSharp.fluid,
                  media: `(min-width: 780px)`,
                },
              ]}
            />
          </BannerContainer>
        )}
      />
    )
  }
}
export default AuthBanner
