import React from 'react'

const SvgHome05 = props => (
  <svg viewBox='0 0 107 123.66' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <g data-name='\u56FE\u5C42 1'>
        <path
          d='M53.5 1.71l-5.39 3A121.92 121.92 0 011.5 19.25v54.27a34.59 34.59 0 0018.29 30.58L53.5 122l33.71-17.9a34.59 34.59 0 0018.29-30.58V19.25A121.92 121.92 0 0158.89 4.69z'
          fill='none'
          stroke='#13cb75'
          strokeMiterlimit={10}
          strokeWidth={3}
        />
        <path
          d='M40 43.65V49H30v36h45V49h-9v-5.35c0-7.81-5.82-14.15-13-14.15s-13 6.34-13 14.15zM72 52v30H33V52h39zm-9-6.83V49H43v-3.87c0-5.9 2.62-12.44 9.5-12.44 7.38 0 10.5 6.54 10.5 12.44z'
          fill='#13cb75'
        />
      </g>
    </g>
  </svg>
)

export default SvgHome05
