import { Billboard } from '@renderbus/common/components'
import { Media } from '@renderbus/common/theme'
import { graphql, Link, StaticQuery } from 'gatsby'
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import RaytoolsRight from '../../images/banner/raytools-banner/raytools-right.png'

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    height: 90vh;
  }
`

const RaytoolsBannerContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88vh;
  gap: 50px;
  img {
    width: 749px;
    margin-left: -140px;
  }
  ${Media.lessThan(Media.small)} {
    flex-direction: column-reverse;
    height: 100%;
    justify-content: flex-end;
    padding-top: 100px;
    > img {
      margin: 0 auto;
      width: 80%;
      margin-top: -20px;
    }
  }
`

const LeftContent = styled.div`
  max-width: 628px;
  text-align: left;
  ${Media.lessThan(Media.small)} {
    max-width: 90.66vw;
    display: flex;
    flex-direction: column;
  }
`

const TopContent = styled.div`
  ${Media.lessThan(Media.small)} {
  }
`
const IntroductionTip = styled.div`
  font-size: 54px;
  font-weight: 800;
  color: #ffffff;
  line-height: 68px;
  span {
    color: #14cc76;
  }
  ${Media.lessThan(Media.small)} {
    font-size: 26px;
    color: #ffffff;
    line-height: 36px;
    width: 320px;
  }
`
const IntroductionSecondTip = styled.div`
  font-size: 18px;
  color: #14cc76;
  line-height: 18px;
  margin-top: 30px;
  margin-bottom: 20px;
  ${Media.lessThan(Media.small)} {
    // display: none;
    margin-top: 10px;
    font-size: 12px;
    margin-bottom: 10px;
  }
`
const IntroductionP = styled.p`
  font-size: 18px;
  color: #999999;
  margin: 0;
  line-height: 36px;
  ${Media.lessThan(Media.small)} {
    font-size: 12px;
    line-height: 24px;
  }
`

const DownloadButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 185px;
  height: 48px;
  background: #15cb75;
  border-radius: 24px 24px 24px 24px;
  margin-top: 50px;
  color: #fff;
  border: none;
  ${Media.lessThan(Media.small)} {
    width: 116px;
    height: 33px;
    margin-top: 20px;
  }
`
const TopTip = styled.div`
  width: 280px;
  height: 50px;
  border: 1px solid #14cc76;
  border-radius: 25px;
  font-size: 24px;
  color: #13cb75;
  line-height: 50px;
  text-align: center;
  margin-bottom: 35px;
  ${Media.lessThan(Media.small)} {
    width: 150px;
    height: 25px;
    font-size: 12px;
    line-height: 25px;
    margin-bottom: 16px;
  }
`

class RaytoolsBanner extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(relativePath: { regex: "/raytools-banner/raytools-banner.png/" }) {
              ...fluidImage
            }
            bannerMb: file(relativePath: { regex: "/raytools-banner/raytools-banner-mb.png/" }) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb }) => (
          <BannerContainer>
            <RaytoolsBannerContent>
              <img src={RaytoolsRight} alt='免费下载' />
              <LeftContent>
                <TopTip>瑞兔百宝箱 Raytool</TopTip>
                <TopContent>
                  <IntroductionTip>
                    一款免费
                    <span>提升3D制作效率</span>
                    的百宝工具箱
                  </IntroductionTip>
                </TopContent>
                <IntroductionSecondTip>
                  目前已推出 3ds Max，Maya，Cinema 4D 特色版本
                </IntroductionSecondTip>
                <IntroductionP>·瑞云自主开发、可无缝集成于常用DCC软件之中的CG工具集</IntroductionP>
                <IntroductionP>·内含多个3D建模、3D渲染便捷功能，精准狙击各种制作难题</IntroductionP>
                <IntroductionP>
                  ·规范制作流程减少制作错误，有效降低渲染时长，高效完成项目
                </IntroductionP>
                <DownloadButton as={Link} to='/raytools.html'>
                  了解详情
                </DownloadButton>
              </LeftContent>
            </RaytoolsBannerContent>
            <Billboard
              fluid={[
                bannerMb.childImageSharp.fluid,
                {
                  ...banner.childImageSharp.fluid,
                  media: `(min-width: 780px)`,
                },
              ]}
            />
          </BannerContainer>
        )}
      />
    )
  }
}
export default RaytoolsBanner
