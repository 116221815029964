import React, { PureComponent } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import CaseBanner from './case-banner'

class InvisibleAlienBanner extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(
              relativePath: { regex: "/banner/invisible-alien-banner/invisible-alien-banner.png/" }
            ) {
              ...fluidImage
            }
            bannerMb: file(
              relativePath: {
                regex: "/banner/invisible-alien-banner/invisible-alien-banner-mobile.png/"
              }
            ) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb }) => <CaseBanner banner={banner} bannerMb={bannerMb} />}
      />
    )
  }
}
export default InvisibleAlienBanner
