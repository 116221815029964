import styled from 'styled-components'
import { H4, Anchor } from '@renderbus/common/components'
import {
  color,
  transition,
  Media,
  spacing,
  typography,
  shadows,
  multiLineTextEllipsis,
} from '@renderbus/common/theme'

export const CardMore = styled(Anchor)`
  display: inline-block;
  margin: 0 ${spacing.base};
  padding-bottom: ${spacing.base};
  color: ${color.primary};
  ${Media.lessThan(Media.small)} {
    margin: 0 ${spacing.small};
    padding-bottom: ${spacing.small};
  }
`
export const CardCover = styled.div`
  overflow: hidden;
  img {
    transition: ${transition(['transform'])};
  }
  :hover {
    cursor: pointer;
    img {
      transform: scale(1.2);
    }
  }
`
export const CardTitle = styled(H4)`
  margin: ${spacing.large} ${spacing.base};
  font-size: ${typography.text};
  font-weight: 300;
  height: 3em;
  ${multiLineTextEllipsis(2)}
  ${Media.lessThan(Media.small)} {
    margin: ${spacing.small};
  }
`
export const Card = styled.div`
  overflow: hidden;
  position: relative;
  box-shadow: ${shadows[1]};
  background-color: ${color.card};
  transition: ${transition('box-shadow')};
  :hover {
    box-shadow: ${shadows[6]};
  }
  img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
    ${Media.lessThan(Media.large)} {
      height: auto;
    }
  }
`
export const CardFooter = styled.div`
  display: flex;
  margin-left: 20px;
  font-size: 16px;
  justify-content: space-between;
  padding-bottom: 10px;
  span {
    color: #999999;
  }
  ${Media.lessThan(Media.small)} {
    font-size: 15px;
  }
`
