import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Billboard } from '@renderbus/common/components'
import { Media } from '@renderbus/common/theme'
import { graphql, StaticQuery } from 'gatsby'
import TitleImg from '../../images/banner/dou-po-cang-qiong/title.png'
import BadgeImg from '../../images/banner/badge.png'

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  text-align: center;
`
const Badge = styled.img`
  position: absolute;
  top: 60px;
  left: 252px;
  ${Media.lessThan(Media.small)} {
    left: 45px;
    width: 74px;
    height: 60px;
  }
`
const TitleWrapper = styled.img`
  position: absolute;
  top: 670px;
  margin: 0 auto;
  left: 50%;
  margin-left: -245.5px;
  ${Media.lessThan(Media.small)} {
    position: relative;
    left: 0;
    top: 0;
    margin-left: 0;
    width: 75%;
    margin-top: 102%;
  }
`

class DouPoCangQiongBanner extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(relativePath: { regex: "/banner/dou-po-cang-qiong/background.png/" }) {
              ...fluidImage
            }
            bannerMb: file(relativePath: { regex: "/banner/dou-po-cang-qiong/backgroundMb.png/" }) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb }) => (
          <BannerContainer>
            <Badge src={BadgeImg} alt='瑞云渲染案例' />
            <TitleWrapper src={TitleImg} alt='国漫战斗特效巅峰之作-《斗破苍穹》第四季来袭！' />
            <Billboard
              fluid={[
                bannerMb.childImageSharp.fluid,
                {
                  ...banner.childImageSharp.fluid,
                  media: `(min-width: 780px)`,
                },
              ]}
              alt='《斗破苍穹》- 七幻青灵'
            />
          </BannerContainer>
        )}
      />
    )
  }
}
export default DouPoCangQiongBanner
