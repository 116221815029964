import React from 'react'
import { Billboard, Button } from '@renderbus/common/components'
import { color } from '@renderbus/common/theme'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { useMedia } from 'use-media'
import { BannerContainer, Emphasis } from './atom'

const Title = styled.p`
  font-size: 62px;
  line-height: 62px;
  font-weight: 800;
  margin: 0;
  color: ${color.white};
  text-shadow: 4px 4px 5px rgba(34, 34, 34, 0.5);
  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 28px;
  }
`

const SubTitle = styled.p`
  font-size: 26px;
  line-height: 26px;
  color: #fcfcfc;
  margin: 20px 0 30px;
  font-weight: 400;
  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 18px;
    margin: 15px 0 25px;
  }
`

const ContentWrapper = styled.div`
  --bg-height: calc(100vh - 160px);
  display: flex;
  flex-flow: column wrap;
  padding-top: calc(0.37 * var(--bg-height));
  padding-left: 15vw;
  height: var(--bg-height);
  align-items: flex-start;
  @media screen and (max-width: 768px) {
    align-items: center;
    padding-top: calc(0.25 * var(--bg-height));
    padding-left: 0;
  }
`

const NewClientBanner = () => {
  const isMobile = useMedia({ maxWidth: 768 })
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "banner/new-client/bg.png" }) {
        ...fluidImage
      }
      bannerMb: file(relativePath: { eq: "banner/new-client/bg-mb.png" }) {
        ...fluidImage
      }
    }
  `)
  return (
    <BannerContainer>
      <ContentWrapper>
        <Title>
          <Emphasis>效果图客户端</Emphasis>焕新上线
        </Title>
        <SubTitle>全新UI界面、新增免费素材库和实用工具箱！</SubTitle>
        <Button
          as='a'
          href='/news/post-id-1151'
          variant={isMobile ? 'outlined' : 'contained'}
          backgroundColor={color.primary}
          style={isMobile ? { color: '#fff' } : {}}
          color={isMobile ? 'primary' : 'default'}
        >
          了解详情
        </Button>
      </ContentWrapper>
      <Billboard
        fluid={[
          data.banner.childImageSharp.fluid,
          {
            ...data.bannerMb.childImageSharp.fluid,
            media: '(max-width: 768px)',
          },
        ]}
        alt='效果图客户端焕新上线，全新UI界面、新增免费素材库和实用工具箱！'
      />
    </BannerContainer>
  )
}

export default NewClientBanner
