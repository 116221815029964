import React from 'react'

const SvgHome06 = props => (
  <svg viewBox='0 0 120 100' {...props}>
    <defs>
      <style>{'.home_06_svg__cls-1{fill:#13cb75}'}</style>
    </defs>
    <g id='home_06_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='home_06_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path className='home_06_svg__cls-1' d='M0 0v100h120V0zm3 3h114v13H3zm0 94V19h114v78z' />
        <path
          className='home_06_svg__cls-1'
          d='M41.41 35.85L22.32 54.94l-2.12 2.13 21.21 21.21 2.12-2.12-19.09-19.09 19.09-19.1-2.12-2.12zM97.68 54.94L78.59 35.85l-2.12 2.12 19.09 19.1-19.09 19.09 2.12 2.12 21.22-21.21-2.13-2.13z'
        />
        <path
          className='home_06_svg__cls-1'
          transform='rotate(-71.32 60.94 57.068)'
          d='M35.43 55.57h51.02v3H35.43z'
        />
      </g>
    </g>
  </svg>
)

export default SvgHome06
