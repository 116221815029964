import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Billboard } from '@renderbus/common/components'
import { Media, hideSMUp, hideSMDown } from '@renderbus/common/theme'
import { graphql, Link, StaticQuery } from 'gatsby'
import ContentImage from '../../images/animation-year-middle/content.png'
import ContentMobileImage from '../../images/animation-year-middle/content-mobile.png'
import DetailBtnImage from '../../images/animation-year-middle/detail-btn.png'
import DetailBtnHoverImage from '../../images/animation-year-middle/detail-btn-hover.png'
import DetailBannerBtnImage from '../../images/animation-year-middle/detail-banner-btn.png'
import DetailBannerBtnHoverImage from '../../images/animation-year-middle/detail-banner-btn-hover.png'
import DetailBannerBtnMb from '../../images/animation-year-middle/detail-banner-btn-mb.png'
import DetailBannerBtnMbHover from '../../images/animation-year-middle/detail-banner-btn-mb-hover.png'
import DetailBtnMbImage from '../../images/animation-year-middle/detail-btn-mb.png'
import DetailBtnMbHoverImage from '../../images/animation-year-middle/detail-btn-mb-hover.png'
import Bg2 from '../../images/animation-year-middle/bg-2.png'
import BgMobile2 from '../../images/animation-year-middle/bg-mobile.png'
import { mbSize } from '@renderbus/common/theme'

const ContentImg = styled.img`
  width: 990px;
  ${hideSMDown};
`
const ContentMobileImg = styled.img`
  ${hideSMUp};
  width: ${mbSize(750)};
`

const DetailButton = styled.div`
  margin-top: -60px;
  width: 250px;
  height: 110px;
  background-image: ${p =>
    p.isBanner ? `url(${DetailBannerBtnImage})` : `url(${DetailBtnImage})`};
  background-size: 100% 100%;
  position: relative;
  &:hover {
    background-image: ${p =>
      p.isBanner ? `url(${DetailBannerBtnHoverImage})` : `url(${DetailBtnHoverImage})`};
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(280)};
    height: ${mbSize(120)};
    margin-top: ${mbSize(-35)};
    background-image: unset;
    &::before {
      content: '';
      display: block;
      width: ${mbSize(280)};
      height: ${mbSize(120)};
      background-image: ${p =>
        p.isBanner ? `url(${DetailBannerBtnMb})` : `url(${DetailBtnMbImage})`};
      background-size: 100% 100%;
    }
    &:hover {
      background-image: unset;
      &::before {
        content: '';
        display: block;
        width: ${mbSize(280)};
        height: ${mbSize(120)};
        background-image: ${p =>
          p.isBanner ? `url(${DetailBannerBtnMbHover})` : `url(${DetailBtnMbHoverImage})`};
        background-size: 100% 100%;
      }
    }
  }
`
const ContentWrapper = styled.div`
  position: relative;
  height: 914px;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${Media.lessThan(Media.small)} {
    margin-top: 0;
    top: ${mbSize(151)};
    height: ${mbSize(434)};
  }
`

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .page-bg {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100vh;
    ${hideSMDown};
  }
  .page-bg-mb {
    ${hideSMUp};
    position: absolute;
    left: 0;
    width: 100%;
  }
  ${Media.lessThan(Media.small)} {
    display: block;
    height: ${mbSize(1624)};
  }
`
const ActivityDate = styled.div`
  color: rgba(17, 17, 17, 1);
  font-size: 16px;
  line-height: 16px;
  position: relative;
  margin-top: -20px;
  p {
    margin: 0;
    line-height: 28px;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(22)};
    color: rgba(17, 17, 17, 1);
    line-height: ${mbSize(26)};
    margin-top: 0;
    p {
      margin: 0;
      line-height: ${mbSize(32)};
    }
  }
`

class AnimationMiddleBanner extends PureComponent {
  render() {
    const {
      buttonLink = '/ysdh-mid-year-recharge.html',
      buttonID = 'ysdhnzhd-ljxq',
      buttonHref = '',
      isBanner = true,
    } = this.props
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(relativePath: { regex: "/animation-year-middle/bg.png/" }) {
              ...fluidImage
            }
            bannerMb: file(relativePath: { regex: "/animation-year-middle/bg-mobile.png/" }) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb }) => (
          <BannerContainer>
            {!isBanner && (
              <>
                <img src={Bg2} alt='' className='page-bg' />
                <img src={BgMobile2} alt='' className='page-bg-mb' />
              </>
            )}
            <ContentWrapper>
              <ContentImg src={ContentImage} alt='' />
              <ContentMobileImg src={ContentMobileImage} alt='' />
              <DetailButton
                id={buttonID}
                as={buttonHref ? 'a' : Link}
                to={buttonLink}
                href={buttonHref}
                isBanner={isBanner}
              ></DetailButton>
              <ActivityDate>
                <p>2024年5月16日10:00～2024年6月26日12:00</p>
                <p>6月27日晚20:00统一直播抽奖</p>
              </ActivityDate>
            </ContentWrapper>
            {isBanner && (
              <Billboard
                fluid={[
                  {
                    ...bannerMb.childImageSharp.fluid,
                  },
                  {
                    ...banner.childImageSharp.fluid,
                    media: `(min-width: 750px)`,
                  },
                ]}
              />
            )}
          </BannerContainer>
        )}
      />
    )
  }
}
export default AnimationMiddleBanner
