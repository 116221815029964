import React from 'react'
import { useCountUp } from 'react-countup'
import { useInViewport } from '@renderbus/common/hooks'
import { Row, Column } from '@renderbus/common/components'

import { Arc, CountText, SubText } from './atoms'

function Zone() {
  const { countUp, start } = useCountUp({ end: 100, delay: 500 })
  const [, setTargetRef] = useInViewport({ onEnter: start })
  return (
    <Arc ref={setTargetRef}>
      <CountText>
        {countUp}
        <SubText> +</SubText>
      </CountText>
      <p>用户覆盖国家/地区</p>
    </Arc>
  )
}
function User() {
  const { countUp, start } = useCountUp({ end: 40, delay: 500 })
  const [, setTargetRef] = useInViewport({ onEnter: start })
  return (
    <Arc ref={setTargetRef}>
      <CountText>
        {countUp}
        <SubText> 万+</SubText>
      </CountText>
      <p>渲染用户</p>
    </Arc>
  )
}
function BoxOffice() {
  const { countUp, start } = useCountUp({ end: 4000, delay: 500 })
  const [, setTargetRef] = useInViewport({ onEnter: start })
  return (
    <Arc ref={setTargetRef}>
      <CountText>
        {countUp}
        <SubText> 万+</SubText>
      </CountText>
      <p>每月渲染帧</p>
    </Arc>
  )
}

class Endorsement extends React.PureComponent {
  render() {
    return (
      <Row>
        <Column xs='6' lg='4'>
          <Zone />
        </Column>
        <Column xs='6' lg='4'>
          <User />
        </Column>
        <Column xs='6' lg='4'>
          <BoxOffice />
        </Column>
      </Row>
    )
  }
}

export default Endorsement
