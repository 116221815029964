import React from 'react'
import styled from 'styled-components'
import { Billboard } from '@renderbus/common/components'
import { Media } from '@renderbus/common/theme'
import { graphql, Link, useStaticQuery } from 'gatsby'
import RCSlogonImg from '../../images/banner/render-contest-2024/slogan.png'
import RCSlogonImgMb from '../../images/banner/render-contest-2024/slogan_mb.png'
import RCLogoImg from '../../images/banner/render-contest-2024/logo.png'
import { useMedia } from 'use-media'

export const RCBannerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: white;
  margin-top: 60px;
  .hideInPC {
    display: none;
  }
  .logo-img {
    position: absolute;
    top: 40px;
    left: 40px;
  }
  ${Media.lessThan(Media.small)} {
    margin-top: unset;
    .hideInMb {
      display: none;
    }
    .hideInPC {
      display: block;
    }
    .logo-img {
      display: none;
    }
  }
`

export const RCBannerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 160px - 60px);
  margin: 0 auto;
  justify-content: end;
  padding: 0 0 60px 0;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    padding: 20px;
  }
`
export const RCTitle = styled.h1`
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 4px;
  ${Media.lessThan(Media.small)} {
    margin-bottom: 4.26vw;
    font-size: 3.73vw;
    letter-spacing: 2px;
  }
`
export const RCSlogon = styled.div`
  position: relative;
  ${Media.lessThan(Media.small)} {
  }
`
export const RCDetailButton = styled(Link)`
  display: block;
  width: 270px;
  line-height: 57px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  color: #17101b;
  background: linear-gradient(225deg, #e1f0ff 0%, #ebf5ff 0%, #4fa2f0 81%, #f4d096 100%);
  border-radius: 127px;
  margin: 22px auto 0;
  :hover {
    background: linear-gradient(225deg, #f4d096 0%, #4fa2f0 0%, #ebf5ff 81%, #e1f0ff 100%);
  }
  ${Media.lessThan(Media.small)} {
    width: 135px;
    height: 30px;
    font-size: 12px;
    margin: 30px auto 0;
    line-height: 30px;
  }
`

const SloganImg = styled.img`
  display: block;
  &.render-time {
    margin-left: 54px;
    margin-top: 38px;
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    margin: 0 auto;
    &.render-time {
      width: 80%;
      margin: 15px auto;
    }
  }
`

export default React.memo(function PictureBanner({ isMain = true, className = '' }) {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { regex: "/banner/render-contest-2024/banner_bg.png/" }) {
        ...fluidImage
      }
      bannerMb: file(relativePath: { regex: "/banner/render-contest-2024/banner_bg_mobile.png/" }) {
        ...fluidImage
      }
    }
  `)
  const isMobile = useMedia({ maxWidth: 600 })
  return (
    <RCBannerContainer>
      <RCBannerContentContainer>
        <React.Fragment>
          {!isMobile && <img src={RCLogoImg} alt='' className='logo-img' />}
          <RCTitle>第三届瑞云3D渲染动画创作大赛</RCTitle>
          <RCSlogon>
            <SloganImg src={RCSlogonImg} className='hideInMb' />
            <SloganImg src={RCSlogonImgMb} className='hideInPC' />
            <RCDetailButton to='/rc2024.html' id='banner-rs'>
              查看详情
            </RCDetailButton>
          </RCSlogon>
        </React.Fragment>
      </RCBannerContentContainer>
      <Billboard
        fluid={[
          data.banner.childImageSharp.fluid,
          { ...data.bannerMb.childImageSharp.fluid, media: `(max-width: 780px)` },
        ]}
      />
    </RCBannerContainer>
  )
})
