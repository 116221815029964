import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Billboard, Button, Flex } from '@renderbus/common/components'
import { Media } from '@renderbus/common/theme'
import { graphql, Link, StaticQuery } from 'gatsby'
import { hideSMDown } from '@renderbus/common/theme/mixin'

import MidYearRechargeTextImg from '../../images/ysdh-mid-year-recharge/midyear-2022-title.png'
import MidYearRechargeTextMbImg from '../../images/ysdh-mid-year-recharge/midyear-2022-mobile-title.png'

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    margin-top: 60px;
    height: ${p => p.mbHeight};
  }
`
const YearendRechargeContent = styled(Flex)`
  flex-direction: column;
  padding-top: 6.25rem;
  p {
    color: #fff;
  }
  ${Media.lessThan(Media.small)} {
    padding-top: 62px;
  }
`
const YearendRechargeText = styled.div`
  margin-bottom: 20px;
  background: ${`url(${MidYearRechargeTextImg})`} no-repeat center center;
  height: 334px;
  z-index: 2;
  margin: 0 auto;
  max-width: 1400px;
  width: 100%;
  ${Media.lessThan(Media.small)} {
    margin-bottom: 16px;
    background: ${`url(${MidYearRechargeTextMbImg})`} no-repeat center center;
    height: 153px;
  }
`
const ToDetailBtn = styled(Button)`
  width: 140px;
  height: 40px;
  line-height: 40px;
  background: linear-gradient(0deg, #f5d37b 0%, #ffeb9b 100%);
  border-radius: 20px;
  color: #2d2c28;
  font-weight: bold;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    width: 120px;
    height: 35px;
    line-height: 35px;
  }
`

const MidYearRechargeTitle = styled.div`
  border: 2px solid #f7d07d;
  border-radius: 22px;
  width: 300px;
  height: 44px;
  position: absolute;
  top: 100px;
  p,
  h1 {
    font-size: 24px;
    line-height: 38px;
    color: #f7d07d;
    text-shadow: 1px 1px 8px rgba(12, 14, 18, 0.3);
    font-weight: 500;
    margin: 0;
  }
  ${Media.lessThan(Media.small)} {
    top: 50px;
    width: 201px;
    height: 30px;
    border-width: 1px;
    h1,
    p {
      font-size: 16px;
      line-height: 28px;
    }
  }
`
const MidYearRechargeTime = styled.div`
  font-size: 24px;
  color: #ffffff;
  text-shadow: 3px 4px 8px #0c0e12;
  position: absolute;
  top: 380px;
  display: flex;
  ${Media.lessThan(Media.small)} {
    font-size: 10px;
    top: 200px;
  }
`
const MidYearRechargeDecorate = styled.div`
  width: 85px;
  height: 5px;
  display: inline-block;
  background: ${p => `linear-gradient(to ${p.gradualDirection},transparent 0%,#fff 100%)`};
  margin: auto 6px;
  ${hideSMDown};
`

class MidYearRecharge2022Banner extends PureComponent {
  render() {
    const {
      buttonLabel = '了解详情',
      buttonLink = '/ysdh-mid-year-recharge.html',
      buttonID = 'lxnz-ljxq',
      buttonHref = '',
      mbHeight = '650px',
      isBanner = true,
    } = this.props
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(relativePath: { regex: "/ysdh-mid-year-recharge/midyear-2022-bg.png/" }) {
              ...fluidImage
            }
            bannerMb: file(
              relativePath: { regex: "/ysdh-mid-year-recharge/midyear-2022-mobile-bg.png/" }
            ) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb }) => (
          <BannerContainer mbHeight={mbHeight}>
            <YearendRechargeContent>
              <MidYearRechargeTitle>
                {isBanner && <p>影视动画渲染用户专享</p>}
                {!isBanner && <h1>影视动画渲染用户专享</h1>}
              </MidYearRechargeTitle>
              <YearendRechargeText />
              <MidYearRechargeTime>
                <MidYearRechargeDecorate gradualDirection='right' />
                5月17日-6月17日充值&nbsp;&nbsp;6月18日开奖
                <MidYearRechargeDecorate gradualDirection='left' />
              </MidYearRechargeTime>
              <ToDetailBtn
                as={buttonHref ? 'a' : Link}
                href={buttonHref}
                to={buttonLink}
                id={buttonID}
              >
                {buttonLabel}
              </ToDetailBtn>
            </YearendRechargeContent>
            <Billboard
              fluid={[
                bannerMb.childImageSharp.fluid,
                {
                  ...banner.childImageSharp.fluid,
                  media: `(min-width: 600px)`,
                },
              ]}
            />
          </BannerContainer>
        )}
      />
    )
  }
}
export default MidYearRecharge2022Banner
