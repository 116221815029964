import React from 'react'
import styled from 'styled-components'
import { Billboard } from '@renderbus/common/components'
import { Media, mbSize } from '@renderbus/common/theme'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Logo from '../../images/banner/double-eleven/logo.png'
import Title from '../../images/banner/double-eleven/title.png'
import SubTitle from '../../images/banner/double-eleven/sub-title.png'

export const DoubleElevenContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: white;
  ${Media.lessThan(Media.small)} {
    padding-top: 150px;
    align-items: flex-start;
    text-align: center;
  }
`

export const DoubleElevenContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 116px;
  width: 1352px;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
  }
`

export const DoubleElevenBannerTitleContainer = styled.div`
  .title-img {
    margin: 42px 0 21px;
    width: 960px;
    height: 142px;
  }
  .sub-title-img {
    width: 650px;
    height: 60px;
    margin-left: -44px;
  }
  ${Media.lessThan(Media.small)} {
    .title-img {
      margin: ${mbSize(37)} 0 ${mbSize(17)};
      width: ${mbSize(572)};
      height: ${mbSize(89)};
    }
    .sub-title-img {
      width: ${mbSize(560)};
      height: ${mbSize(49)};
      margin-left: 0;
    }
  }
`

export const DoubleElevenTitle = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 295px;
    height: 27px;
    margin-right: 17px;
  }
  div {
    width: 153px;
    height: 35px;
    background: #60a5ff;
    border-radius: 18px;
    font-weight: 400;
    font-size: 15px;
    color: #dee4fe;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${Media.lessThan(Media.small)} {
    justify-content: center;
    img {
      width: ${mbSize(308)};
      height: ${mbSize(29)};
      margin-right: ${mbSize(17)};
    }
    div {
      width: ${mbSize(173)};
      height: ${mbSize(40)};
      border-radius: ${mbSize(20)};
      font-size: ${mbSize(18)};
    }
  }
`

export const DateTime = styled.div`
  font-weight: 400;
  font-size: 22px;
  color: #ffffff;
  line-height: 35px;
  margin: 45px 0 66px;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(20)};
    line-height: ${mbSize(28)};
    margin: ${mbSize(33)} 0 ${mbSize(33)};
  }
`

export const DoubleElevenBannerButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 139px;
  height: 44px;
  border-radius: 22px;
  font-size: 18px;
  font-weight: 400;
  color: white;
  background: linear-gradient(90deg, #fa4fae 0%, #fdae74 99%);
  transition: all 0.3s;
  :hover {
    box-shadow: 0px 4px 10px 0px #2b5be4;
  }
  ${Media.lessThan(Media.small)} {
    margin: 20px auto 20px;
    width: ${mbSize(240)};
    height: ${mbSize(60)};
    line-height: ${mbSize(60)};
    font-size: ${mbSize(24)};
    padding: 0;
  }
`

export default React.memo(function DoubleElevenBanner() {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { regex: "/banner/double-eleven/banner-bg.png/" }) {
        ...fluidImage
      }
      bannerMb: file(relativePath: { regex: "/banner/double-eleven/banner-bg-mb.png/" }) {
        ...fluidImage
      }
    }
  `)

  return (
    <DoubleElevenContainer>
      <DoubleElevenContent>
        <DoubleElevenBannerTitleContainer>
          <DoubleElevenTitle>
            <img src={Logo} alt='' />
            <div>效果图用户专享</div>
          </DoubleElevenTitle>
          <img src={Title} alt='' className='title-img' />
          <img src={SubTitle} alt='' className='sub-title-img' />
          <DateTime>活动时间：2024.11.1-2024.11.30</DateTime>
          <DoubleElevenBannerButton to='/architectural-rendering-monthly-promotion.html?utm_source=index-banner'>
            了解详情
          </DoubleElevenBannerButton>
        </DoubleElevenBannerTitleContainer>
      </DoubleElevenContent>
      <Billboard
        fluid={[
          data.banner.childImageSharp.fluid,
          { ...data.bannerMb.childImageSharp.fluid, media: `(max-width: 780px)` },
        ]}
      />
    </DoubleElevenContainer>
  )
})
