import React from 'react'

const SvgHome04 = props => (
  <svg viewBox='0 0 111 123.43' {...props}>
    <defs>
      <style>
        {'.home_04_svg__cls-1{fill:none;stroke:#13cb75;stroke-miterlimit:10;stroke-width:3px}'}
      </style>
    </defs>
    <g id='home_04_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='home_04_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='home_04_svg__cls-1'
          d='M55.5 1.72l-54 29.9v60.19l54 29.91 54-29.91V31.62l-54-29.9z'
        />
        <path
          className='home_04_svg__cls-1'
          d='M51.46 89.83L27.5 76.14V49.37l24 15.4-.04 25.06zM30.47 43.56L55 29.55l24.54 14.02-24.49 15.77-24.58-15.78zM82.5 76.16l-24 13.71.04-25.11L82.5 49.33v26.83z'
        />
      </g>
    </g>
  </svg>
)

export default SvgHome04
