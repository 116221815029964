import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Billboard, Button, Flex } from '@renderbus/common/components'
import { Media, spacing, typography, hideSMDown, hideSMUp } from '@renderbus/common/theme'
import { graphql, StaticQuery } from 'gatsby'
import PicImg from '../../images/index/cloud-production-pic.png'
import TitleImg from '../../images/index/cloud-production-title.png'
import TitleMbImg from '../../images/index/cloud-production-title-mb.png'

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  text-align: center;
  a {
    margin-top: ${spacing.base};
    width: 200px;
    font-size: 20px;
  }
  ${Media.lessThan(Media.small)} {
    a {
      width: 160px;
      font-size: ${typography.h4};
    }
    > div:first-child {
      padding-top: 80px;
      flex-direction: column;
    }
  }
`
const TitleWrapper = styled.div`
  position: relative;
  text-align: left;
  color: white;
  ${Media.lessThan(Media.small)} {
    width: 90%;
    text-align: center;
  }
`
const Title = styled.img`
  ${hideSMDown};
`
const TitleMb = styled.img`
  ${hideSMUp};
  width: 90%;
`
const Copy = styled.p`
  font-size: 20px;
  margin-top: 40px;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.text};
  }
`
const Item = styled.span`
  &:not(:last-child)::after {
    content: '|';
    margin: 0 15px;
  }
  ${Media.lessThan(Media.small)} {
    &:not(:last-child)::after {
      margin: 0 5px;
    }
  }
`
const Picture = styled.img`
  ${Media.lessThan(Media.small)} {
    width: 90%;
  }
`
const List = ['海量机器', '资源共享', '协同办公', '高速传输', '云端协同制作']
class CloudProductionBanner extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(relativePath: { regex: "/index/cloud-production.png/" }) {
              ...fluidImage
            }
            bannerMb: file(relativePath: { regex: "/index/cloud-production-mb.png/" }) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb }) => (
          <BannerContainer>
            <Flex style={{ height: '90%' }}>
              <TitleWrapper>
                <Title src={TitleImg} alt='云渲染云制作方案 助推影视动画产业上云' />
                <TitleMb src={TitleMbImg} alt='云渲染云制作方案 助推影视动画产业上云' />
                <Copy>
                  {List.map((item, index) => (
                    <Item key={index}>{item}</Item>
                  ))}
                </Copy>
                <Button as='a' href='/collaborative-cloud-production.html' variant='outlined'>
                  了解更多
                </Button>
              </TitleWrapper>
              <Picture src={PicImg} alt='' />
            </Flex>
            <Billboard
              fluid={[
                bannerMb.childImageSharp.fluid,
                {
                  ...banner.childImageSharp.fluid,
                  media: `(min-width: 780px)`,
                },
              ]}
              alt='云渲染云制作方案 助推影视动画产业上云'
            />
          </BannerContainer>
        )}
      />
    )
  }
}
export default CloudProductionBanner
