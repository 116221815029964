import React, { PureComponent } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Billboard, Button, Flex } from '@renderbus/common/components'
import { Media, hideSMDown, hideSMUp, spacing, typography } from '@renderbus/common/theme'
import BoxImg from '../../images/banner/xgt-membership-month/box.png'
import TitleImg from '../../images/banner/xgt-membership-month/title.png'
import TitleMbImg from '../../images/banner/xgt-membership-month/title-mb.png'

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  ${Media.lessThan(Media.small)} {
    > div:first-child {
      padding-top: 200px;
      flex-direction: column;
    }
  }
`
const Title = styled.img`
  ${hideSMDown};
`
const TitleMb = styled.img`
  ${hideSMUp};
`
const Tip = styled.p`
  color: #a48669;
  font-size: 1.25rem;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.text};
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 73px;
  button {
    background: linear-gradient(0deg, #dfa97b, #f0d8c2);
    color: #000;
    margin-top: 40px;
    :hover {
      background: linear-gradient(0deg, #925e28, #f0d8c2);
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-right: 0;
    align-items: center;
    img {
      width: 90%;
    }
    button {
      margin-top: ${spacing.base};
    }
    p {
      margin: ${spacing.small};
    }
  }
`
const Box = styled.img`
  ${Media.lessThan(Media.small)} {
    width: 90%;
    margin-top: -30px;
  }
`
class XGTMembershipMonthBanner extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(relativePath: { regex: "/banner/xgt-membership-month/background.png/" }) {
              ...fluidImage
            }
            bannerMb: file(
              relativePath: { regex: "/banner/xgt-membership-month/background-mb.png/" }
            ) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb }) => (
          <BannerContainer>
            <Flex style={{ height: '90%' }}>
              <Wrapper>
                <Title src={TitleImg} alt='效果图会员月 限时加赠 会员享受五折渲染+送免单券' />
                <TitleMb src={TitleMbImg} alt='效果图会员月 限时加赠 会员享受五折渲染+送免单券' />
                <Button
                  id='ban-xgthy'
                  onMouseDown={() => {
                    window.location.href = '/xgt-membership-month.html'
                  }}
                >
                  查看详情
                </Button>
                <Tip>活动时间：2021.4.15—5.15</Tip>
              </Wrapper>
              <Box src={BoxImg} alt=''></Box>
            </Flex>
            <Billboard
              fluid={[
                bannerMb.childImageSharp.fluid,
                {
                  ...banner.childImageSharp.fluid,
                  media: `(min-width: 780px)`,
                },
              ]}
              alt='瑞云效果图会员月充值活动'
            />
          </BannerContainer>
        )}
      />
    )
  }
}
export default XGTMembershipMonthBanner
