import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Billboard } from '@renderbus/common/components'
import { Media, hideSMUp } from '@renderbus/common/theme'
import { graphql, StaticQuery } from 'gatsby'
import RayvisionCaseImg from '../../images/banner/rayvision-side-case.png'
import BannerTitleMb from '../../images/index/green-snake-title-mb.png'

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    .green-snake {
      > picture:last-of-type {
        img {
          object-fit: contain !important;
        }
      }
    }
  }
`
const Layer = styled.div`
  width: 1400px;
  height: 100%;
  margin: 0 auto;
  ${Media.lessThan(Media.small)} {
    width: 90%;
  }
`
const RayvisionCaseImgWrapper = styled.img`
  position: absolute;
  top: 100px;
  left: 0;
  ${Media.lessThan(Media.small)} {
    height: 30px;
  }
`
const Title = styled.img`
  ${hideSMUp};
  position: absolute;
  width: 50%;
  bottom: 160px;
  left: 50%;
  transform: translateX(-93px);
`

class GreenSnakeBanner extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(relativePath: { regex: "/index/banner-green-snake.png/" }) {
              ...fluidImage
            }
            bannerMb: file(relativePath: { regex: "/index/banner-green-snake-mb.png/" }) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb }) => (
          <BannerContainer>
            <Layer>
              <RayvisionCaseImgWrapper src={RayvisionCaseImg} alt='瑞云渲染案例' />
              <Title src={BannerTitleMb} alt='7月23日全国上映' />
            </Layer>
            <Billboard
              className='green-snake'
              fluid={[
                bannerMb.childImageSharp.fluid,
                {
                  ...banner.childImageSharp.fluid,
                  media: `(min-width: 780px)`,
                },
              ]}
              alt='青蛇Ⅱ-7月23日全国上映'
            />
          </BannerContainer>
        )}
      />
    )
  }
}
export default GreenSnakeBanner
