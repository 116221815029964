import React from 'react'
import { Media, mbSize } from '@renderbus/common/theme'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Billboard } from '@renderbus/common/components'
import diamond from '../../images/banner/animation-activity/diamond.png'
import { UserAuthorizationService, baseLink } from '@renderbus/common/service'

export const AnimationActivityBannerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: white;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    justify-content: start;
    padding-top: 16vh;
  }
`

export const LeftWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 1200px;
  margin-bottom: 150px;
  ${Media.lessThan(Media.small)} {
    width: unset;
    align-items: center;
    margin: 0;
  }
`

export const Tip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 250px;
  height: 46px;
  border-radius: 23px;
  font-weight: bold;
  font-size: 20px;
  color: #13cb75;
  background: rgba(18, 204, 117, 0.1);
  > img {
    width: 23px;
    margin-top: 2px;
  }
  > span {
    background: linear-gradient(0deg, #3fc470 20%, #3fbc2b 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(300)};
    height: ${mbSize(56)};
    border-radius: ${mbSize(28)};
    font-size: ${mbSize(24)};
    > img {
      width: ${mbSize(28)};
      height: ${mbSize(24)};
      margin-top: 0;
    }
    span {
      margin-top: 1px;
    }
  }
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 60px;
  color: #ffffff;
  margin: 40px 0 0;
  line-height: 72px;
  p {
    margin: 0;
  }
  ${Media.lessThan(Media.small)} {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: ${mbSize(72)};
    margin: ${mbSize(30)} 0 0;
    line-height: ${mbSize(96)};
  }
`

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 45px;
  border-radius: 45px;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  margin-top: 30px;
  background: radial-gradient(circle at 100%, #3fbc2b 0%, #40c570 100%);
  transition: all 0.3s;
  :hover {
    box-shadow: 0px 10px 30px 10px rgba(64, 194, 93, 0.3);
  }
  cursor: pointer;
  ${Media.lessThan(Media.small)} {
    font-weight: bold;
    width: ${mbSize(240)};
    height: ${mbSize(60)};
    font-size: ${mbSize(24)};
    margin-top: ${mbSize(40)};
  }
`

export default React.memo(function AnimationActivityBanner() {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { regex: "/banner/animation-activity/banner_bg.png/" }) {
        ...fluidImage
      }
      bannerMb: file(relativePath: { regex: "/banner/animation-activity/banner_bg_mobile.png/" }) {
        ...fluidImage
      }
    }
  `)

  const handleClick = e => {
    e.nativeEvent.stopImmediatePropagation()
    if (typeof window !== 'undefined') {
      window.location.href = !!UserAuthorizationService.isLogined()
        ? baseLink()
        : `${baseLink()}/sso/register`
    }
  }

  return (
    <AnimationActivityBannerContainer>
      <LeftWrap>
        <Tip>
          <img src={diamond} alt='diamond' />
          <span>影视动画用户专享</span>
        </Tip>
        <Title>
          <p>瑞云渲染新用户福利</p>
          <p style={{ color: '#FFECB2' }}>钜惠来袭！</p>
        </Title>
        <Button onClick={handleClick}>注册领取</Button>
      </LeftWrap>
      <Billboard
        fluid={[
          data.banner.childImageSharp.fluid,
          { ...data.bannerMb.childImageSharp.fluid, media: `(max-width: 780px)` },
        ]}
      />
    </AnimationActivityBannerContainer>
  )
})
