import React from 'react'
import styled from 'styled-components'
import { Billboard } from '@renderbus/common/components'
import { Media } from '@renderbus/common/theme'
import { graphql, Link, useStaticQuery } from 'gatsby'
import RCSlogonImg from '../../images/banner/render-contest-2023/slogan.png'
import SloganMain from '../../images/banner/render-contest-2023/slogan-main.png'
import Time from '../../images/banner/render-contest-2023/time.png'

export const RCBannerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: white;
  ${Billboard}::after {
    content: '';
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: -3px;
    z-index: 100;
    background: linear-gradient(transparent, #15145c);
    filter: blur(1px);
  }
  ${Media.lessThan(Media.small)} {
  }
`

export const RCBannerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: self-start;
  width: 1200px;
  height: calc(100vh - 160px - 60px);
  margin: 0 auto;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    padding: 20px;
  }
`
export const RCTitle = styled.h4`
  margin-left: ${p => (p.isMain ? '65px' : '135px')};
  margin-bottom: ${p => (p.isMain ? '20px' : '2.59vh')};
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 15px;
  ${Media.lessThan(Media.small)} {
    opacity: ${p => !p.isMain && '0'};
    margin-top: ${p => (p.isMain ? '41.26vw' : '38.15vh')};
    margin-left: ${p => (p.isMain ? '8.81vw' : '6.93vw')};
    margin-left: 20px;
    margin-bottom: 2.1vh;
    font-size: 1.5vh;
    letter-spacing: 7px;
  }
`
export const RCSlogon = styled.div`
  width: 59.62vh;
  height: 27.22vh;
  position: relative;
  &.rc-logo {
    background: url(${RCSlogonImg}) no-repeat center / auto 27.22vh;
    margin-left: 80px;
  }
  ${Media.lessThan(Media.small)} {
    display: ${p => !p.isMain && 'none'};
    width: 85.98vw;
    height: 39.25vw;
    margin-left: ${p => (p.isMain ? '6.94vw' : '0')};
    margin-left: 0;
  }
`
export const RCDate = styled.span`
  position: absolute;
  bottom: 5.27vh;
  left: ${p => (p.isMain ? '195px' : '175px')};
  width: max-content;
  font-weight: bold;
  letter-spacing: 6.2px;
  ${Media.lessThan(Media.small)} {
    bottom: 7.6vw;
    left: ${p => (p.isMain ? '20.53vw' : '25.6vw')};
    font-size: 1.5vh;
    letter-spacing: ${p => (p.isMain ? '2px' : '3.1px')};
  }
`
export const RCDetailButton = styled(Link)`
  display: block;
  width: 160px;
  line-height: 40px;
  margin-left: 54px;
  text-align: center;
  font-weight: bold;
  color: #050706;
  background: linear-gradient(90deg, #f7fc2a 0%, #3fffc6 100%);
  border-radius: 20px;
  ${Media.lessThan(Media.small)} {
    width: 120px;
    height: 8.01vw;
    font-size: 12px;
    margin: 20px auto 0;
    line-height: 8.01vw;
  }
`

const SloganImg = styled.img`
  display: block;
  &.render-time {
    margin-left: 54px;
    margin-top: 38px;
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    margin: 0 auto;
    &.render-time {
      width: 80%;
      margin: 15px auto;
    }
  }
`

export default React.memo(function PictureBanner({ isMain = true, className = '' }) {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { regex: "/banner/render-contest-2023/banner_bg.png/" }) {
        ...fluidImage
      }
      bannerMb: file(relativePath: { regex: "/banner/render-contest-2023/banner_bg_mb.png/" }) {
        ...fluidImage
      }
    }
  `)
  return (
    <RCBannerContainer>
      <RCBannerContentContainer>
        {isMain && (
          <React.Fragment>
            <RCTitle isMain={isMain}>第二届3D渲染动画创作大赛</RCTitle>
            <RCSlogon isMain={isMain} className={className}>
              {!isMain && <RCDate isMain={isMain}>2022.12.8-2023.3.8（UTC+8）</RCDate>}
              {isMain && (
                <>
                  <SloganImg src={SloganMain} />
                  <RCDetailButton to='/rc.html' id='banner-rs'>
                    查看详情
                  </RCDetailButton>
                  <SloganImg className='render-time' src={Time} />
                </>
              )}
            </RCSlogon>
          </React.Fragment>
        )}
      </RCBannerContentContainer>

      <Billboard
        fluid={[
          data.banner.childImageSharp.fluid,
          { ...data.bannerMb.childImageSharp.fluid, media: `(max-width: 780px)` },
        ]}
      />
    </RCBannerContainer>
  )
})
