import React from 'react'
import { Media, mbSize } from '@renderbus/common/theme'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Billboard } from '@renderbus/common/components'
import diamond from '../../images/banner/giveback-old-user-banner/diamond.png'
import dragon from '../../images/banner/giveback-old-user-banner/dragon.png'
import dragonMb from '../../images/banner/giveback-old-user-banner/dragon_mobile.png'
import gold from '../../images/banner/giveback-old-user-banner/gold_icon.png'
import TitleImg from '../../images/banner/giveback-old-user-banner/title.png'
import { useMedia } from 'use-media'

export const PictureBannerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: white;
  gap: 5%;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    justify-content: start;
    padding-top: 16vh;
    gap: ${mbSize(10)};
  }
`

export const LeftWrap = styled.div`
  display: flex;
  .gold-icon {
    width: 54px;
    height: 84px;
    margin: 24px 17px 0 117px;
  }
  .left-content {
    display: flex;
    flex-direction: column;
    margin-bottom: 150px;
    min-width: 471px;
  }
  ${Media.lessThan(Media.small)} {
    width: unset;
    margin: 0;
    position: relative;
    .gold-icon {
      position: absolute;
      width: ${mbSize(55)};
      height: ${mbSize(78)};
      top: ${mbSize(40)};
      left: ${mbSize(-40)};
      margin: 0;
    }
    .left-content {
      align-items: center;
      margin: 0;
      min-width: unset;
    }
  }
`

export const RightWrap = styled.div`
  img {
    width: 810px;
    margin-bottom: 133px;
  }
  ${Media.lessThan(Media.small)} {
    img {
      width: ${mbSize(750)};
      margin-bottom: 0;
    }
  }
`

export const Tip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 248px;
  height: 44px;
  border-radius: 22px;
  border: 2px solid #ffecb2;
  font-size: 20px;
  vertical-align: middle;
  > img {
    width: 23px;
    margin-top: 2px;
  }
  > span {
    color: #ffecb2;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(340)};
    height: ${mbSize(50)};
    border-radius: ${mbSize(25)};
    font-size: ${mbSize(24)};
    border: ${mbSize(2)} solid #ffecb2;
    > img {
      width: ${mbSize(28)};
      height: ${mbSize(24)};
      margin-top: 0;
    }
  }
`

export const Title = styled.div`
  margin: 22px 0 0 -7px;
  img {
    width: 325px;
  }
  ${Media.lessThan(Media.small)} {
    margin: ${mbSize(29)} 0 0;
    img {
      width: ${mbSize(371)};
    }
  }
`
export const Desc = styled.div`
  font-weight: 400;
  font-size: 24px;
  color: #ffffff;
  line-height: 24px;
  margin-top: 4px;
  font-family: 'Source Han Sans CN';
  letter-spacing: 1px;
  span {
    font-weight: bold;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(30)};
    margin: ${mbSize(16)} 0 0;
    line-height: unset;
    letter-spacing: ${mbSize(1)};
  }
`

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 45px;
  border-radius: 45px;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  margin-top: 30px;
  border: 2px solid #f2c8aa;
  background: linear-gradient(-180deg, #ff9933 0%, #ff3333 100%);
  transition: all 0.3s;
  :hover {
    box-shadow: 0 10px 30px 10px rgba(191, 0, 0, 0.3);
  }
  cursor: pointer;
  ${Media.lessThan(Media.small)} {
    font-weight: bold;
    width: ${mbSize(240)};
    height: ${mbSize(60)};
    font-size: ${mbSize(24)};
    margin-top: ${mbSize(40)};
  }
`
export const DescSecond = styled.div`
  font-weight: 400;
  font-size: 18px;
  color: #dddddd;
  line-height: 18px;
  margin-top: 12px;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(24)};
    margin: ${mbSize(10)} 0 0;
    line-height: unset;
  }
`

export default React.memo(function PictureUserActivityBanner() {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { regex: "/banner/giveback-old-user-banner/banner_bg.png/" }) {
        ...fluidImage
      }
      bannerMb: file(
        relativePath: { regex: "/banner/giveback-old-user-banner/banner_bg_mobile.png/" }
      ) {
        ...fluidImage
      }
    }
  `)
  const isMobile = useMedia({ maxWidth: 768 })

  return (
    <PictureBannerContainer>
      <LeftWrap>
        <img src={gold} alt='' className='gold-icon' />
        <div className='left-content'>
          <Tip>
            <img src={diamond} alt='diamond' />
            <span>效果图回归用户专享</span>
          </Tip>
          <Title>
            <img src={TitleImg} alt='' />
          </Title>
          <Desc>
            登录即可领取<span>25元渲染券及1个月会员权益</span>
          </Desc>
          <DescSecond>达标条件：180天以上未登录的效果图用户</DescSecond>
          <Button as='a' href='/3d-rendering-special-offer.html'>
            立即参与
          </Button>
        </div>
      </LeftWrap>
      <RightWrap>
        <img src={isMobile ? dragonMb : dragon} alt='' />
      </RightWrap>
      <Billboard
        fluid={[
          data.banner.childImageSharp.fluid,
          { ...data.bannerMb.childImageSharp.fluid, media: `(max-width: 780px)` },
        ]}
      />
    </PictureBannerContainer>
  )
})
