import React from 'react'
import { Media, mbSize } from '@renderbus/common/theme'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { useMedia } from 'use-media'
import { Billboard } from '@renderbus/common/components'
import diamond from '../../images/banner/free-sample/diamond.png'
import { UserAuthorizationService, baseLink } from '@renderbus/common/service'

export const FreeSampleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: white;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    justify-content: start;
    padding-top: 16vh;
  }
`

export const LeftWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 1200px;
  margin-bottom: 150px;
  ${Media.lessThan(Media.small)} {
    width: unset;
    align-items: center;
    margin: 0;
  }
`

export const Tip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 240px;
  height: 45px;
  border-radius: 23px;
  border: 1px solid #ffecb2;
  font-weight: bold;
  font-size: 20px;
  color: #ffecb2;
  > img {
    width: 23px;
    height: 20px;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(280)};
    height: ${mbSize(54)};
    border-radius: ${mbSize(27)};
    font-size: ${mbSize(24)};
    > img {
      width: ${mbSize(28)};
      height: ${mbSize(24)};
    }
  }
`

export const Title = styled.h1`
  font-weight: bold;
  font-size: 42px;
  color: #ffffff;
  margin: 30px 0 0;
  line-height: 42px;
  ${Media.lessThan(Media.small)} {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: ${mbSize(60)};
    margin: ${mbSize(30)} 0 0;
    line-height: ${mbSize(80)};
  }
`

export const Desc = styled.div`
  font-weight: 400;
  font-size: 24px;
  color: #cccccc;
  line-height: 24px;
  margin-top: 20px;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(30)};
    margin: ${mbSize(30)} 0 0;
    line-height: unset;
  }
`

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 45px;
  border-radius: 45px;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  margin-top: 30px;
  background: radial-gradient(circle at 100%, #3bc1ed 0%, #2071ff 100%);
  transition: all 0.3s;
  :hover {
    box-shadow: 0px 10px 30px 10px #1256cc4d;
  }
  cursor: pointer;
  ${Media.lessThan(Media.small)} {
    font-weight: bold;
    width: ${mbSize(240)};
    height: ${mbSize(60)};
    font-size: ${mbSize(24)};
    margin-top: ${mbSize(40)};
  }
`

export default React.memo(function FreeSampleBanner() {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { regex: "/banner/free-sample/banner_bg.png/" }) {
        ...fluidImage
      }
      bannerMb: file(relativePath: { regex: "/banner/free-sample/banner_bg_mobile.png/" }) {
        ...fluidImage
      }
    }
  `)
  const isMobile = useMedia({ maxWidth: 600 })

  const handleClick = e => {
    e.nativeEvent.stopImmediatePropagation()
    if (typeof window !== 'undefined') {
      window.location.href = !!UserAuthorizationService.isLogined()
        ? baseLink()
        : `${baseLink()}/sso/register`
    }
  }

  return (
    <FreeSampleContainer>
      <LeftWrap>
        <Tip>
          <img src={diamond} alt='diamond' />
          效果图用户专享
        </Tip>
        <Title>
          <span>新福利强势上线{!isMobile && ': '}</span>
          <span style={{ color: '#FFECB2' }}>免费体验渲染小样!</span>
        </Title>
        <Desc>一键提交免费渲染，解放电脑从现在开始</Desc>
        <Button onClick={handleClick}>注册体验</Button>
      </LeftWrap>
      <Billboard
        fluid={[
          data.banner.childImageSharp.fluid,
          { ...data.bannerMb.childImageSharp.fluid, media: `(max-width: 780px)` },
        ]}
      />
    </FreeSampleContainer>
  )
})
