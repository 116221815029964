import styled from 'styled-components'
import { color, typography, Media } from '@renderbus/common/theme'

export const Arc = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 260px;
  height: 260px;
  margin: 0.5em auto;
  border-radius: 50%;
  padding-bottom: 1em;
  color: ${color.primary};
  border: 4px solid ${color.primary};
  border-bottom-color: transparent;
  > p {
    margin: 0;
  }
  ${Media.lessThan(Media.small)} {
    width: 150px;
    height: 150px;
    > p {
      font-size: 10px;
    }
  }
`
export const CountText = styled.span`
  font-weight: bold;
  font-size: 60px; /* fuck design */
  ${Media.lessThan(Media.small)} {
    font-size: 36px; /* fuck design */
  }
`

export const SubText = styled.sub`
  font-weight: bold;
  vertical-align: baseline;
  font-size: ${typography.h3};
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.text};
  }
`
