import React from 'react'
import { Media, mbSize } from '@renderbus/common/theme'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Billboard } from '@renderbus/common/components'
import TitleImg from '../../images/banner/animation-graduation-banner/title.png'
import { UserAuthorizationService, baseLink } from '@renderbus/common/service'

export const PictureBannerContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  color: white;
  padding-top: 200px;
  font-family: 'Source Han Sans CN';
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    justify-content: start;
    padding-top: 32vw;
    /* ${Billboard} {
      height: ${mbSize(1334)};
    } */
  }
`

export const BannerWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  img {
    width: 675px;
    margin: 40px 0 20px;
  }
  ${Media.lessThan(Media.small)} {
    img {
      width: ${mbSize(660)};
      margin: ${mbSize(40)} 0 ${mbSize(30)};
    }
  }
`
export const TopTitle = styled.div`
  background: rgba(9, 102, 59, 0.4);
  font-size: 24px;
  color: #ffffff;
  border-radius: 25px;
  width: 400px;
  height: 50px;
  line-height: 50px;
  box-shadow: 0px 5px 10px 10px rgba(0, 0, 0, 0.4);
  letter-spacing: 2px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(400)};
    height: ${mbSize(50)};
    line-height: ${mbSize(50)};
    font-size: ${mbSize(24)};
    border-radius: ${mbSize(25)};
  }
`

export const Desc = styled.div`
  font-size: 30px;
  color: #ffffff;
  line-height: 36px;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.4);
  letter-spacing: 3px;
  p {
    margin: 0;
  }
  span {
    color: #ffecb2;
    font-weight: bold;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(30)};
    line-height: unset;
    letter-spacing: ${mbSize(2)};
  }
`

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 40px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  margin-top: 40px;
  background: radial-gradient(circle at 100%, #3fbc2b 0%, #40c570 100%);
  transition: all 0.3s;
  :hover {
    box-shadow: 0px 10px 30px 10px rgba(64, 194, 93, 0.3);
  }
  cursor: pointer;
  ${Media.lessThan(Media.small)} {
    font-weight: bold;
    width: ${mbSize(240)};
    height: ${mbSize(60)};
    line-height: ${mbSize(60)};
    font-size: ${mbSize(24)};
    margin-top: ${mbSize(40)};
  }
`

export default React.memo(function AnimationGraduationBanner() {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { regex: "/banner/animation-graduation-banner/banner_bg.jpg/" }) {
        ...fluidImage
      }
      bannerMb: file(
        relativePath: { regex: "/banner/animation-graduation-banner/banner_bg_mobile.png/" }
      ) {
        ...fluidImage
      }
    }
  `)

  const handleClick = e => {
    e.nativeEvent.stopImmediatePropagation()
    if (typeof window !== 'undefined') {
      window.location.href = !!UserAuthorizationService.isLogined()
        ? baseLink()
        : `${baseLink()}/sso/register`
    }
  }

  return (
    <PictureBannerContainer>
      <BannerWrap>
        <TopTitle>教育优惠与学生折扣钜惠来袭</TopTitle>
        <img src={TitleImg} alt='' />
        <Desc>
          学生注册认证即享<span>5折渲染+40元无门槛渲染券</span>
        </Desc>
        <Button onClick={handleClick}>立即注册</Button>
      </BannerWrap>
      <Billboard
        fluid={[
          data.banner.childImageSharp.fluid,
          { ...data.bannerMb.childImageSharp.fluid, media: `(max-width: 780px)` },
        ]}
      />
    </PictureBannerContainer>
  )
})
