import styled from 'styled-components'
import { H1 } from '@renderbus/common/components'
import { Media, typography, color } from '@renderbus/common/theme'

export const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  min-height: 720px;
  z-index: 0;
  text-align: center;
  ${H1} {
    font-size: 60px;
    text-shadow: rgba(0, 0, 0, 0.75) 3px 0 3px;
  }
  ${Media.lessThan(Media.small)} {
    ${H1} {
      font-size: ${typography.h2};
    }
  }
`

export const Emphasis = styled.em`
  color: ${color.primary};
  font-style: normal;
`

export const Container = styled.div`
  display: flex;
  position: relative;
  height: 100vh;
  padding-top: 60px;
`
